@charset "UTF-8";
/* Heading sizes are not final ones. Need to be specified with the design team. */
@font-face {
  font-family: "Icomoon Lexy";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/icomoon-lexy/icomoon-lexy.eot");
  src: url("../fonts/icomoon-lexy/icomoon-lexy.eot?#iefix") format("embedded-opentype"), url("../fonts/icomoon-lexy/icomoon-lexy.woff") format("woff"), url("../fonts/icomoon-lexy/icomoon-lexy.ttf") format("truetype"), url("../fonts/icomoon-lexy/icomoon-lexy.svg") format("svg"); }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

article, aside, details, figcaption, figure, dialog,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

a {
  text-decoration: none;
  font-size: 100%;
  color: inherit; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important; }

@-webkit-keyframes autofill {
  to {
    color: #000000;
    background: transparent; } }

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }

html {
  position: relative;
  min-height: 100%;
  box-sizing: border-box; }

body {
  color: #000000;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  background-color: #F5F5F5;
  outline: none;
  word-break: break-word;
  font-weight: 400; }

*,
*:before,
*:after {
  outline: none;
  box-sizing: inherit; }

.container {
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

.container-sm {
  width: 100%;
  max-width: 860px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

a {
  transition: 0.1s; }
  a:hover {
    opacity: 0.7; }

.main-content {
  padding: 62px 0;
  margin-top: 176px; }

p, a, h1, h2, h3, h4, h5, h6, div, span, ul, li, header, footer, section, main, aside, button, input, textarea, label, sub, sup, strong {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icomoon Lexy" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-hyperlink:before {
  content: ""; }

.icon-redo:before {
  content: ""; }

.icon-undo:before {
  content: ""; }

.icon-underline:before {
  content: ""; }

.icon-strikethrough:before {
  content: ""; }

.icon-italic:before {
  content: ""; }

.icon-bold:before {
  content: ""; }

.icon-corner:before {
  content: ""; }

.icon-add:before {
  content: ""; }

.icon-print:before {
  content: ""; }

.icon-download:before {
  content: ""; }

.icon-note:before {
  content: ""; }

.icon-edit:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-camera:before {
  content: ""; }

.icon-avatar:before {
  content: ""; }

.icon-time:before {
  content: ""; }

.icon-delete:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-file:before {
  content: ""; }

.icon-link:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-upload:before {
  content: ""; }

.icon-clock:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-doc:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-back:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-star:before {
  content: ""; }

.icon-eye:before {
  content: ""; }

/* Header styles */
header .brand-logo {
  width: 100%;
  max-width: 110px;
  margin-right: 22px; }
  header .brand-logo img {
    max-width: 100%;
    height: auto; }

header .nav-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  header .nav-items .logo {
    font-size: 25px;
    font-weight: 600;
    margin-right: 100px; }
  header .nav-items .nav li a {
    padding: 20px 29px; }
  header .nav-items .nav {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700; }
    header .nav-items .nav li .btn {
      min-width: 130px; }

header.main {
  background-color: #002e59;
  height: 100%;
  max-height: 176px;
  color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4; }
  header.main .search {
    padding-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    header.main .search .text-field {
      width: 100%;
      max-width: 641px; }
      header.main .search .text-field input {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }

header.no-search {
  max-height: 110px;
  position: absolute; }

header.dashboard {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid rgba(83, 134, 212, 0.2);
  height: 64px;
  margin-left: 320px;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  header.dashboard .container {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  header.dashboard .text-field {
    transition: 0.2s;
    position: absolute;
    width: 100%;
    z-index: 1;
    align-items: center;
    display: none; }
    header.dashboard .text-field input {
      height: 63px;
      line-height: 63px;
      box-shadow: 1px 1px 3px rgba(65, 103, 161, 0.16);
      display: none;
      padding-right: 125px; }
    header.dashboard .text-field .btn-icon {
      position: absolute;
      right: 45px;
      display: none; }
    header.dashboard .text-field.show {
      display: flex; }
      header.dashboard .text-field.show input {
        display: block; }
        header.dashboard .text-field.show input:focus {
          box-shadow: 2px 2px 10px rgba(65, 103, 161, 0.3); }
      header.dashboard .text-field.show .btn-icon {
        display: block; }
  header.dashboard .details {
    position: relative; }
    header.dashboard .details .name-avatar {
      background-color: #5386D4;
      color: #ffffff;
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      text-transform: uppercase;
      display: inline-block;
      cursor: pointer;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      font-size: 13px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    header.dashboard .details #dropdown-label {
      line-height: 36px; }
    header.dashboard .details .details-dropdown {
      position: absolute;
      border-radius: 24px;
      background-color: #ffffff;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
      right: 0;
      top: 45px;
      padding: 26px 0 10px 0;
      display: none;
      z-index: 3;
      min-width: 300px; }
      header.dashboard .details .details-dropdown .dropdown-content {
        padding: 0 10px 17px 26px;
        border-bottom: 1px solid rgba(83, 134, 212, 0.1);
        width: 300px; }
        header.dashboard .details .details-dropdown .dropdown-content p, header.dashboard .details .details-dropdown .dropdown-content h3 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 200px; }
  header.dashboard.full {
    margin-left: 0; }
    header.dashboard.full .container {
      justify-content: space-between; }
  header.dashboard .profile-left {
    justify-content: flex-start;
    border-bottom: none; }
    header.dashboard .profile-left a {
      color: #5386D4; }

/* Footer styles */
footer {
  background-color: #30699F;
  padding: 14px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }
  footer.dashboard {
    margin-left: 320px; }

/* Aside styles */
aside {
  background-color: #002e59;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 320px;
  box-shadow: 1px 1px 3px rgba(65, 103, 161, 0.3);
  overflow-y: auto;
  z-index: 1;
  /* When the logo will be ready the code for the logo needs to be revised */ }
  aside .header {
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 48px;
    height: 64px; }
  aside .logo {
    max-width: 110px;
    margin-left: 67px; }
    aside .logo a {
      font-size: 21px;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      color: rgba(255, 255, 255, 0.5);
      font-weight: 600;
      line-height: 64px;
      display: block; }
      aside .logo a img {
        max-width: 100%;
        vertical-align: middle; }
  aside .nav {
    margin-bottom: 30px; }
    aside .nav li {
      color: rgba(255, 255, 255, 0.7);
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      font-weight: 400;
      text-transform: capitalize;
      height: 45px;
      line-height: 45px;
      position: relative;
      display: flex; }
      aside .nav li a {
        display: inline-block;
        width: 100%;
        padding-left: 67px;
        color: rgba(255, 255, 255, 0.7); }
        aside .nav li a i {
          position: absolute;
          left: 32px;
          line-height: 45px;
          font-size: 17px; }
          aside .nav li a i.icon-star, aside .nav li a i.icon-plus {
            font-size: 20px; }
        aside .nav li a:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          background-color: transparent;
          border-radius: 10px;
          width: 5px; }
        aside .nav li a.active {
          background-color: rgba(0, 0, 0, 0.15);
          color: #FFFFFF; }
          aside .nav li a.active:before {
            background-color: #FFFFFF; }
          aside .nav li a.active ~ span {
            background-color: rgba(0, 0, 0, 0.15);
            color: #FFFFFF; }
        aside .nav li a:hover {
          color: #FFFFFF;
          opacity: 1; }
      aside .nav li span {
        padding-right: 15px;
        width: 100%;
        max-width: 65px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      aside .nav li.title {
        margin-left: 67px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.4);
        margin-right: 40px;
        font-weight: 600; }
    aside .nav li, aside .nav a {
      max-width: 320px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

/* admin main styles */
.admin-content {
  margin: 24px 0 70px 320px; }

.filters .select-box .react-select__multi-value {
  display: none; }

.loader-container {
  width: 100px;
  margin: 0 auto; }

@media (min-width: 360px) {
  .cols-xxs-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-12 > [class^="col"]:not(.ml-0), .cols-xxs-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-12 > [class^="col"], .cols-xxs-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-xxs-12 > [class^="col"]:nth-child(12n + 1), .cols-xxs-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-xxs-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-11 > [class^="col"]:not(.ml-0), .cols-xxs-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-11 > [class^="col"], .cols-xxs-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-xxs-11 > [class^="col"]:nth-child(11n + 1), .cols-xxs-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-xxs-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-10 > [class^="col"]:not(.ml-0), .cols-xxs-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-10 > [class^="col"], .cols-xxs-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-xxs-10 > [class^="col"]:nth-child(10n + 1), .cols-xxs-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-xxs-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-9 > [class^="col"]:not(.ml-0), .cols-xxs-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-9 > [class^="col"], .cols-xxs-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-xxs-9 > [class^="col"]:nth-child(9n + 1), .cols-xxs-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-xxs-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-8 > [class^="col"]:not(.ml-0), .cols-xxs-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-8 > [class^="col"], .cols-xxs-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-xxs-8 > [class^="col"]:nth-child(8n + 1), .cols-xxs-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-xxs-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-7 > [class^="col"]:not(.ml-0), .cols-xxs-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-7 > [class^="col"], .cols-xxs-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-xxs-7 > [class^="col"]:nth-child(7n + 1), .cols-xxs-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-xxs-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-6 > [class^="col"]:not(.ml-0), .cols-xxs-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-6 > [class^="col"], .cols-xxs-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-xxs-6 > [class^="col"]:nth-child(6n + 1), .cols-xxs-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-xxs-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-5 > [class^="col"]:not(.ml-0), .cols-xxs-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-5 > [class^="col"], .cols-xxs-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-xxs-5 > [class^="col"]:nth-child(5n + 1), .cols-xxs-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-xxs-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-4 > [class^="col"]:not(.ml-0), .cols-xxs-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-4 > [class^="col"], .cols-xxs-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-xxs-4 > [class^="col"]:nth-child(4n + 1), .cols-xxs-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-xxs-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-3 > [class^="col"]:not(.ml-0), .cols-xxs-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-3 > [class^="col"], .cols-xxs-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-xxs-3 > [class^="col"]:nth-child(3n + 1), .cols-xxs-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-xxs-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-2 > [class^="col"]:not(.ml-0), .cols-xxs-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-2 > [class^="col"], .cols-xxs-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-xxs-2 > [class^="col"]:nth-child(2n + 1), .cols-xxs-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 480px) {
  .cols-xs-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-12 > [class^="col"]:not(.ml-0), .cols-xs-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-12 > [class^="col"], .cols-xs-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-xs-12 > [class^="col"]:nth-child(12n + 1), .cols-xs-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-xs-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-11 > [class^="col"]:not(.ml-0), .cols-xs-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-11 > [class^="col"], .cols-xs-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-xs-11 > [class^="col"]:nth-child(11n + 1), .cols-xs-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-xs-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-10 > [class^="col"]:not(.ml-0), .cols-xs-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-10 > [class^="col"], .cols-xs-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-xs-10 > [class^="col"]:nth-child(10n + 1), .cols-xs-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-xs-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-9 > [class^="col"]:not(.ml-0), .cols-xs-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-9 > [class^="col"], .cols-xs-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-xs-9 > [class^="col"]:nth-child(9n + 1), .cols-xs-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-xs-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-8 > [class^="col"]:not(.ml-0), .cols-xs-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-8 > [class^="col"], .cols-xs-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-xs-8 > [class^="col"]:nth-child(8n + 1), .cols-xs-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-xs-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-7 > [class^="col"]:not(.ml-0), .cols-xs-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-7 > [class^="col"], .cols-xs-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-xs-7 > [class^="col"]:nth-child(7n + 1), .cols-xs-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-xs-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-6 > [class^="col"]:not(.ml-0), .cols-xs-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-6 > [class^="col"], .cols-xs-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-xs-6 > [class^="col"]:nth-child(6n + 1), .cols-xs-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-xs-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-5 > [class^="col"]:not(.ml-0), .cols-xs-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-5 > [class^="col"], .cols-xs-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-xs-5 > [class^="col"]:nth-child(5n + 1), .cols-xs-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-xs-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-4 > [class^="col"]:not(.ml-0), .cols-xs-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-4 > [class^="col"], .cols-xs-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-xs-4 > [class^="col"]:nth-child(4n + 1), .cols-xs-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-xs-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-3 > [class^="col"]:not(.ml-0), .cols-xs-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-3 > [class^="col"], .cols-xs-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-xs-3 > [class^="col"]:nth-child(3n + 1), .cols-xs-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-xs-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-2 > [class^="col"]:not(.ml-0), .cols-xs-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-2 > [class^="col"], .cols-xs-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-xs-2 > [class^="col"]:nth-child(2n + 1), .cols-xs-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 768px) {
  .cols-sm-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-12 > [class^="col"]:not(.ml-0), .cols-sm-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-12 > [class^="col"], .cols-sm-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-sm-12 > [class^="col"]:nth-child(12n + 1), .cols-sm-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-sm-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-11 > [class^="col"]:not(.ml-0), .cols-sm-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-11 > [class^="col"], .cols-sm-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-sm-11 > [class^="col"]:nth-child(11n + 1), .cols-sm-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-sm-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-10 > [class^="col"]:not(.ml-0), .cols-sm-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-10 > [class^="col"], .cols-sm-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-sm-10 > [class^="col"]:nth-child(10n + 1), .cols-sm-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-sm-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-9 > [class^="col"]:not(.ml-0), .cols-sm-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-9 > [class^="col"], .cols-sm-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-sm-9 > [class^="col"]:nth-child(9n + 1), .cols-sm-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-sm-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-8 > [class^="col"]:not(.ml-0), .cols-sm-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-8 > [class^="col"], .cols-sm-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-sm-8 > [class^="col"]:nth-child(8n + 1), .cols-sm-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-sm-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-7 > [class^="col"]:not(.ml-0), .cols-sm-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-7 > [class^="col"], .cols-sm-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-sm-7 > [class^="col"]:nth-child(7n + 1), .cols-sm-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-sm-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-6 > [class^="col"]:not(.ml-0), .cols-sm-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-6 > [class^="col"], .cols-sm-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-sm-6 > [class^="col"]:nth-child(6n + 1), .cols-sm-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-sm-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-5 > [class^="col"]:not(.ml-0), .cols-sm-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-5 > [class^="col"], .cols-sm-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-sm-5 > [class^="col"]:nth-child(5n + 1), .cols-sm-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-sm-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-4 > [class^="col"]:not(.ml-0), .cols-sm-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-4 > [class^="col"], .cols-sm-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-sm-4 > [class^="col"]:nth-child(4n + 1), .cols-sm-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-sm-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-3 > [class^="col"]:not(.ml-0), .cols-sm-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-3 > [class^="col"], .cols-sm-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-sm-3 > [class^="col"]:nth-child(3n + 1), .cols-sm-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-sm-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-2 > [class^="col"]:not(.ml-0), .cols-sm-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-2 > [class^="col"], .cols-sm-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-sm-2 > [class^="col"]:nth-child(2n + 1), .cols-sm-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 1024px) {
  .cols-md-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-12 > [class^="col"]:not(.ml-0), .cols-md-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-12 > [class^="col"], .cols-md-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-md-12 > [class^="col"]:nth-child(12n + 1), .cols-md-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-md-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-11 > [class^="col"]:not(.ml-0), .cols-md-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-11 > [class^="col"], .cols-md-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-md-11 > [class^="col"]:nth-child(11n + 1), .cols-md-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-md-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-10 > [class^="col"]:not(.ml-0), .cols-md-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-10 > [class^="col"], .cols-md-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-md-10 > [class^="col"]:nth-child(10n + 1), .cols-md-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-md-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-9 > [class^="col"]:not(.ml-0), .cols-md-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-9 > [class^="col"], .cols-md-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-md-9 > [class^="col"]:nth-child(9n + 1), .cols-md-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-md-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-8 > [class^="col"]:not(.ml-0), .cols-md-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-8 > [class^="col"], .cols-md-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-md-8 > [class^="col"]:nth-child(8n + 1), .cols-md-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-md-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-7 > [class^="col"]:not(.ml-0), .cols-md-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-7 > [class^="col"], .cols-md-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-md-7 > [class^="col"]:nth-child(7n + 1), .cols-md-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-md-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-6 > [class^="col"]:not(.ml-0), .cols-md-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-6 > [class^="col"], .cols-md-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-md-6 > [class^="col"]:nth-child(6n + 1), .cols-md-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-md-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-5 > [class^="col"]:not(.ml-0), .cols-md-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-5 > [class^="col"], .cols-md-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-md-5 > [class^="col"]:nth-child(5n + 1), .cols-md-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-md-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-4 > [class^="col"]:not(.ml-0), .cols-md-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-4 > [class^="col"], .cols-md-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-md-4 > [class^="col"]:nth-child(4n + 1), .cols-md-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-md-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-3 > [class^="col"]:not(.ml-0), .cols-md-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-3 > [class^="col"], .cols-md-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-md-3 > [class^="col"]:nth-child(3n + 1), .cols-md-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-md-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-2 > [class^="col"]:not(.ml-0), .cols-md-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-2 > [class^="col"], .cols-md-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-md-2 > [class^="col"]:nth-child(2n + 1), .cols-md-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 1280px) {
  .cols-lg-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-12 > [class^="col"]:not(.ml-0), .cols-lg-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-12 > [class^="col"], .cols-lg-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-lg-12 > [class^="col"]:nth-child(12n + 1), .cols-lg-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-lg-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-11 > [class^="col"]:not(.ml-0), .cols-lg-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-11 > [class^="col"], .cols-lg-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-lg-11 > [class^="col"]:nth-child(11n + 1), .cols-lg-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-lg-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-10 > [class^="col"]:not(.ml-0), .cols-lg-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-10 > [class^="col"], .cols-lg-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-lg-10 > [class^="col"]:nth-child(10n + 1), .cols-lg-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-lg-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-9 > [class^="col"]:not(.ml-0), .cols-lg-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-9 > [class^="col"], .cols-lg-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-lg-9 > [class^="col"]:nth-child(9n + 1), .cols-lg-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-lg-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-8 > [class^="col"]:not(.ml-0), .cols-lg-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-8 > [class^="col"], .cols-lg-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-lg-8 > [class^="col"]:nth-child(8n + 1), .cols-lg-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-lg-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-7 > [class^="col"]:not(.ml-0), .cols-lg-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-7 > [class^="col"], .cols-lg-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-lg-7 > [class^="col"]:nth-child(7n + 1), .cols-lg-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-lg-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-6 > [class^="col"]:not(.ml-0), .cols-lg-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-6 > [class^="col"], .cols-lg-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-lg-6 > [class^="col"]:nth-child(6n + 1), .cols-lg-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-lg-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-5 > [class^="col"]:not(.ml-0), .cols-lg-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-5 > [class^="col"], .cols-lg-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-lg-5 > [class^="col"]:nth-child(5n + 1), .cols-lg-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-lg-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-4 > [class^="col"]:not(.ml-0), .cols-lg-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-4 > [class^="col"], .cols-lg-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-lg-4 > [class^="col"]:nth-child(4n + 1), .cols-lg-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-lg-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-3 > [class^="col"]:not(.ml-0), .cols-lg-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-3 > [class^="col"], .cols-lg-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-lg-3 > [class^="col"]:nth-child(3n + 1), .cols-lg-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-lg-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-2 > [class^="col"]:not(.ml-0), .cols-lg-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-2 > [class^="col"], .cols-lg-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-lg-2 > [class^="col"]:nth-child(2n + 1), .cols-lg-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 360px) {
  [class^="cols-"] .col-xxs-12, [class*=" cols-"] .col-xxs-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-xxs-11, [class*=" cols-"] .col-xxs-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-xxs-10, [class*=" cols-"] .col-xxs-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-xxs-9, [class*=" cols-"] .col-xxs-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-xxs-8, [class*=" cols-"] .col-xxs-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-xxs-7, [class*=" cols-"] .col-xxs-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-xxs-6, [class*=" cols-"] .col-xxs-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-xxs-5, [class*=" cols-"] .col-xxs-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-xxs-4, [class*=" cols-"] .col-xxs-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-xxs-3, [class*=" cols-"] .col-xxs-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-xxs-2, [class*=" cols-"] .col-xxs-2 {
    width: calc(16.66667% - 8.33333px); } }

@media (min-width: 480px) {
  [class^="cols-"] .col-xs-12, [class*=" cols-"] .col-xs-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-xs-11, [class*=" cols-"] .col-xs-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-xs-10, [class*=" cols-"] .col-xs-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-xs-9, [class*=" cols-"] .col-xs-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-xs-8, [class*=" cols-"] .col-xs-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-xs-7, [class*=" cols-"] .col-xs-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-xs-6, [class*=" cols-"] .col-xs-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-xs-5, [class*=" cols-"] .col-xs-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-xs-4, [class*=" cols-"] .col-xs-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-xs-3, [class*=" cols-"] .col-xs-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-xs-2, [class*=" cols-"] .col-xs-2 {
    width: calc(16.66667% - 8.33333px); } }

@media (min-width: 768px) {
  [class^="cols-"] .col-sm-12, [class*=" cols-"] .col-sm-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-sm-11, [class*=" cols-"] .col-sm-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-sm-10, [class*=" cols-"] .col-sm-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-sm-9, [class*=" cols-"] .col-sm-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-sm-8, [class*=" cols-"] .col-sm-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-sm-7, [class*=" cols-"] .col-sm-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-sm-6, [class*=" cols-"] .col-sm-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-sm-5, [class*=" cols-"] .col-sm-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-sm-4, [class*=" cols-"] .col-sm-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-sm-3, [class*=" cols-"] .col-sm-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-sm-2, [class*=" cols-"] .col-sm-2 {
    width: calc(16.66667% - 8.33333px); } }

@media (min-width: 1024px) {
  [class^="cols-"] .col-md-12, [class*=" cols-"] .col-md-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-md-11, [class*=" cols-"] .col-md-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-md-10, [class*=" cols-"] .col-md-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-md-9, [class*=" cols-"] .col-md-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-md-8, [class*=" cols-"] .col-md-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-md-7, [class*=" cols-"] .col-md-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-md-6, [class*=" cols-"] .col-md-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-md-5, [class*=" cols-"] .col-md-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-md-4, [class*=" cols-"] .col-md-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-md-3, [class*=" cols-"] .col-md-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-md-2, [class*=" cols-"] .col-md-2 {
    width: calc(16.66667% - 8.33333px); } }

@media (min-width: 1280px) {
  [class^="cols-"] .col-lg-12, [class*=" cols-"] .col-lg-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-lg-11, [class*=" cols-"] .col-lg-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-lg-10, [class*=" cols-"] .col-lg-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-lg-9, [class*=" cols-"] .col-lg-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-lg-8, [class*=" cols-"] .col-lg-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-lg-7, [class*=" cols-"] .col-lg-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-lg-6, [class*=" cols-"] .col-lg-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-lg-5, [class*=" cols-"] .col-lg-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-lg-4, [class*=" cols-"] .col-lg-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-lg-3, [class*=" cols-"] .col-lg-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-lg-2, [class*=" cols-"] .col-lg-2 {
    width: calc(16.66667% - 8.33333px); } }

.cols-mb > [class^="col"], .cols-mb > [class*=" col"] {
  margin-bottom: 10px; }

/* Button styles */
.btn {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: 0.3s;
  padding: 0 15px;
  display: inline-block; }
  .btn.filled {
    border: 0;
    color: #ffffff; }
    .btn.filled.primary {
      background-color: #5386D4; }
    .btn.filled.primary-70 {
      background-color: rgba(83, 134, 212, 0.7); }
    .btn.filled.secondary {
      background-color: #000000; }
    .btn.filled.tertiary {
      background-color: #818ea7; }
    .btn.filled.highlight {
      background-color: #39a4cd; }
    .btn.filled.warning {
      background-color: #E43535; }
    .btn.filled.white {
      background-color: #FFFFFF; }
    .btn.filled.white-30 {
      background-color: rgba(255, 255, 255, 0.3); }
    .btn.filled.grey {
      background-color: #707070; }
    .btn.filled.black-20 {
      background-color: rgba(0, 0, 0, 0.2); }
    .btn.filled.black-30 {
      background-color: rgba(0, 0, 0, 0.3); }
    .btn.filled.black-50 {
      background-color: rgba(0, 0, 0, 0.5); }
    .btn.filled.black-60 {
      background-color: rgba(0, 0, 0, 0.6); }
    .btn.filled.black-70 {
      background-color: rgba(0, 0, 0, 0.7); }
    .btn.filled.blue-light {
      background-color: #D4E1F4; }
    .btn.filled.blue-light-60 {
      background-color: rgba(212, 225, 244, 0.6); }
  .btn.outline {
    line-height: 36px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid transparent; }
    .btn.outline.text-primary {
      color: #5386D4; }
    .btn.outline.text-primary-70 {
      color: rgba(83, 134, 212, 0.7); }
    .btn.outline.text-secondary {
      color: #000000; }
    .btn.outline.text-tertiary {
      color: #818ea7; }
    .btn.outline.text-highlight {
      color: #39a4cd; }
    .btn.outline.text-warning {
      color: #E43535; }
    .btn.outline.text-white {
      color: #FFFFFF; }
    .btn.outline.text-white-30 {
      color: rgba(255, 255, 255, 0.3); }
    .btn.outline.text-grey {
      color: #707070; }
    .btn.outline.text-black-20 {
      color: rgba(0, 0, 0, 0.2); }
    .btn.outline.text-black-30 {
      color: rgba(0, 0, 0, 0.3); }
    .btn.outline.text-black-50 {
      color: rgba(0, 0, 0, 0.5); }
    .btn.outline.text-black-60 {
      color: rgba(0, 0, 0, 0.6); }
    .btn.outline.text-black-70 {
      color: rgba(0, 0, 0, 0.7); }
    .btn.outline.text-blue-light {
      color: #D4E1F4; }
    .btn.outline.text-blue-light-60 {
      color: rgba(212, 225, 244, 0.6); }
    .btn.outline.border-primary {
      border-color: #5386D4; }
    .btn.outline.border-primary-70 {
      border-color: rgba(83, 134, 212, 0.7); }
    .btn.outline.border-secondary {
      border-color: #000000; }
    .btn.outline.border-tertiary {
      border-color: #818ea7; }
    .btn.outline.border-highlight {
      border-color: #39a4cd; }
    .btn.outline.border-warning {
      border-color: #E43535; }
    .btn.outline.border-white {
      border-color: #FFFFFF; }
    .btn.outline.border-white-30 {
      border-color: rgba(255, 255, 255, 0.3); }
    .btn.outline.border-grey {
      border-color: #707070; }
    .btn.outline.border-black-20 {
      border-color: rgba(0, 0, 0, 0.2); }
    .btn.outline.border-black-30 {
      border-color: rgba(0, 0, 0, 0.3); }
    .btn.outline.border-black-50 {
      border-color: rgba(0, 0, 0, 0.5); }
    .btn.outline.border-black-60 {
      border-color: rgba(0, 0, 0, 0.6); }
    .btn.outline.border-black-70 {
      border-color: rgba(0, 0, 0, 0.7); }
    .btn.outline.border-blue-light {
      border-color: #D4E1F4; }
    .btn.outline.border-blue-light-60 {
      border-color: rgba(212, 225, 244, 0.6); }
  .btn.link {
    border: 0;
    background-color: transparent;
    box-shadow: none; }
    .btn.link.primary {
      color: #5386D4; }
    .btn.link.primary-70 {
      color: rgba(83, 134, 212, 0.7); }
    .btn.link.secondary {
      color: #000000; }
    .btn.link.tertiary {
      color: #818ea7; }
    .btn.link.highlight {
      color: #39a4cd; }
    .btn.link.warning {
      color: #E43535; }
    .btn.link.white {
      color: #FFFFFF; }
    .btn.link.white-30 {
      color: rgba(255, 255, 255, 0.3); }
    .btn.link.grey {
      color: #707070; }
    .btn.link.black-20 {
      color: rgba(0, 0, 0, 0.2); }
    .btn.link.black-30 {
      color: rgba(0, 0, 0, 0.3); }
    .btn.link.black-50 {
      color: rgba(0, 0, 0, 0.5); }
    .btn.link.black-60 {
      color: rgba(0, 0, 0, 0.6); }
    .btn.link.black-70 {
      color: rgba(0, 0, 0, 0.7); }
    .btn.link.blue-light {
      color: #D4E1F4; }
    .btn.link.blue-light-60 {
      color: rgba(212, 225, 244, 0.6); }
    .btn.link:active {
      background-color: transparent !important; }
  .btn:hover {
    opacity: 0.7; }
  .btn.br-corner {
    border-radius: 19px; }
  .btn.br-round {
    border-radius: 29px; }
  .btn.shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .btn[disabled], .btn.disabled {
    border: 0;
    pointer-events: none;
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(83, 134, 212, 0.3) !important; }
  .btn.w-sm {
    min-width: 130px; }
  .btn.w-md {
    min-width: 152px; }
  .btn.w-lg {
    min-width: 200px; }
  .btn.w-xl {
    min-width: 232px; }
  .btn.h-sm {
    height: 38px; }
    .btn.h-sm.outline {
      line-height: 36px; }
    .btn.h-sm.filled {
      line-height: 38px; }
    .btn.h-sm.link {
      line-height: 38px; }
  .btn.h-lg {
    height: 47px; }
    .btn.h-lg.outline {
      line-height: 45px; }
    .btn.h-lg.filled {
      line-height: 47px; }
    .btn.h-lg.link {
      line-height: 47px; }

/* Buttons including icons */
.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.1s;
  display: flex; }
  .btn-icon:hover {
    opacity: 0.7; }

/* Text field styles */
.text-field {
  position: relative;
  margin-bottom: 35px;
  /* Invalid state */ }
  .text-field input, .text-field textarea, .text-field .ql-editor {
    width: 100%;
    padding: 0 28px;
    color: #000000;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    transition: 0.1s;
    border: 1px solid transparent; }
    .text-field input::placeholder, .text-field textarea::placeholder, .text-field .ql-editor::placeholder {
      color: rgba(0, 0, 0, 0.5);
      opacity: 1; }
  .text-field input {
    height: 47px; }
  .text-field textarea {
    resize: vertical;
    min-height: 50px;
    padding: 15px 28px; }
  .text-field.br-corner input, .text-field.br-corner textarea {
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.3); }
    .text-field.br-corner input:focus, .text-field.br-corner textarea:focus {
      padding-left: 28px;
      border-width: 2px;
      border-color: #5386D4;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16); }
  .text-field.br-corner label {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    margin-bottom: 4px;
    margin-left: 28px; }
  .text-field.icon.right-bg input {
    padding-right: 55px; }
  .text-field.icon.right-bg .icon {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #5386D4;
    width: 48px;
    height: 47px;
    border-radius: 30px;
    text-align: center;
    color: #ffffff;
    transition: 0.1s; }
    .text-field.icon.right-bg .icon i {
      line-height: 47px; }
    .text-field.icon.right-bg .icon:hover {
      opacity: 0.7; }
  .text-field.icon.right input {
    padding-right: 50px; }
  .text-field.icon.right i {
    position: absolute;
    right: 25px;
    font-size: 20px;
    line-height: 47px; }
  .text-field.invalid input, .text-field.invalid textarea {
    border-width: 2px;
    border-color: #E43535; }
  .text-field.mb-0 {
    margin-bottom: 0; }
  .text-field.disabled {
    pointer-events: none;
    opacity: 0.5; }
    .text-field.disabled input {
      background-color: rgba(212, 225, 244, 0.4); }
  .text-field.sm {
    width: 155px; }

.error-msg {
  font-size: 11px;
  position: absolute;
  bottom: -20px;
  color: #E43535;
  text-align: left;
  font-weight: 400;
  margin-bottom: 0; }
  .error-msg.width-md {
    min-width: 130px; }

/* Select-box styles */
.select-box {
  margin-bottom: 35px;
  position: relative; }
  .select-box .checkbox {
    display: flex !important;
    align-items: center; }
  .select-box .react-select__control {
    min-height: 47px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: #000000;
    cursor: pointer; }
  .select-box .react-select-container {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 16px;
    color: #000000; }
  .select-box .react-select__control {
    box-shadow: none; }
  .select-box .react-select__value-container {
    padding: 0 26px; }
  .select-box .react-select__value-container, .select-box .react-select__single-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 93%; }
  .select-box .react-select__indicator-separator {
    display: none; }
  .select-box .react-select__dropdown-indicator {
    padding-right: 30px; }
  .select-box .react-select__dropdown-indicator svg {
    fill: rgba(0, 0, 0, 0.5); }
  .select-box .react-select__menu {
    margin-top: 0;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    text-align: left;
    border: 2px solid #5386D4;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  .select-box .react-select__option--is-focused {
    background-color: rgba(0, 0, 0, 0.1);
    color: #5386D4; }
    .select-box .react-select__option--is-focused:active {
      background-color: rgba(0, 0, 0, 0.2); }
  .select-box .react-select__option--is-selected, .select-box .react-select__option {
    padding: 10px 27px;
    cursor: pointer; }
  .select-box .react-select__option--is-selected {
    background-color: rgba(83, 134, 212, 0.15);
    color: #000000; }
  .select-box .react-select__placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .select-box.br-corner .react-select__control {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.3);
    border-radius: 30px; }
  .select-box.br-corner .react-select__control {
    border-radius: 24px; }
    .select-box.br-corner .react-select__control.react-select__control--menu-is-open.react-select__control {
      border-radius: 24px 24px 0 0;
      border-width: 2px;
      border-color: #5386D4;
      border-bottom: none; }
  .select-box.br-corner label {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    margin-bottom: 4px;
    margin-left: 28px; }
  .select-box .value {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 28px; }
    .select-box .value .circle {
      width: 5px;
      height: 5px;
      min-width: 5px;
      background: #000000;
      border-radius: 50%;
      display: inline-block;
      margin: 0 10px 2px 10px; }
    .select-box .value.hide {
      display: none; }
  .select-box.check-items.br-corner label {
    margin-left: 0; }
  .select-box.mb-0 {
    margin-bottom: 0; }
  .select-box.invalid.br-corner .react-select__control {
    border: 2px solid #E43535; }
  .select-box.disabled {
    pointer-events: none;
    opacity: 0.5; }
    .select-box.disabled .react-select__control {
      background-color: rgba(212, 225, 244, 0.4); }

/* Typography */
/* Text colors */
.color-primary {
  color: #5386D4; }

.color-primary-70 {
  color: rgba(83, 134, 212, 0.7); }

.color-secondary {
  color: #000000; }

.color-tertiary {
  color: #818ea7; }

.color-highlight {
  color: #39a4cd; }

.color-warning {
  color: #E43535; }

.color-white {
  color: #FFFFFF; }

.color-white-30 {
  color: rgba(255, 255, 255, 0.3); }

.color-grey {
  color: #707070; }

.color-black-20 {
  color: rgba(0, 0, 0, 0.2); }

.color-black-30 {
  color: rgba(0, 0, 0, 0.3); }

.color-black-50 {
  color: rgba(0, 0, 0, 0.5); }

.color-black-60 {
  color: rgba(0, 0, 0, 0.6); }

.color-black-70 {
  color: rgba(0, 0, 0, 0.7); }

.color-blue-light {
  color: #D4E1F4; }

.color-blue-light-60 {
  color: rgba(212, 225, 244, 0.6); }

/* Font family */
.font-primary {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

.font-secondary {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

/* Font sizes */
.fs-xs {
  font-size: 12px; }

.fs-sm {
  font-size: 14px; }

.fs-md {
  font-size: 16px; }

.fs-lg {
  font-size: 18px; }

.fs-xl {
  font-size: 24px; }

.fs-xxl {
  font-size: 30px; }

/* Heading sizes */
h6, .h6 {
  font-size: 12px; }

h5, .h5 {
  font-size: 14px; }

h4, .h4 {
  font-size: 16px; }

h3, .h3 {
  font-size: 18px; }

h2, .h2 {
  font-size: 24px; }

h1, .h1 {
  font-size: 30px; }

/* Font weights */
.fw-100 {
  font-weight: 100; }

.fw-200 {
  font-weight: 200; }

.fw-300 {
  font-weight: 300; }

.fw-400 {
  font-weight: 400; }

.fw-500 {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.fw-700 {
  font-weight: 700; }

.fw-800 {
  font-weight: 800; }

.fw-900 {
  font-weight: 900; }

.italic {
  font-style: italic; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.m-1 {
  margin: 5px; }

.mt-1 {
  margin-top: 5px; }

.mr-1 {
  margin-right: 5px; }

.mb-1 {
  margin-bottom: 5px; }

.ml-1 {
  margin-left: 5px; }

.mx-1 {
  margin-left: 5px;
  margin-right: 5px; }

.my-1 {
  margin-top: 5px;
  margin-bottom: 5px; }

.p-1 {
  padding: 5px; }

.pt-1 {
  padding-top: 5px; }

.pr-1 {
  padding-right: 5px; }

.pb-1 {
  padding-bottom: 5px; }

.pl-1 {
  padding-left: 5px; }

.px-1 {
  padding-left: 5px;
  padding-right: 5px; }

.py-1 {
  padding-top: 5px;
  padding-bottom: 5px; }

.m-2 {
  margin: 10px; }

.mt-2 {
  margin-top: 10px; }

.mr-2 {
  margin-right: 10px; }

.mb-2 {
  margin-bottom: 10px; }

.ml-2 {
  margin-left: 10px; }

.mx-2 {
  margin-left: 10px;
  margin-right: 10px; }

.my-2 {
  margin-top: 10px;
  margin-bottom: 10px; }

.p-2 {
  padding: 10px; }

.pt-2 {
  padding-top: 10px; }

.pr-2 {
  padding-right: 10px; }

.pb-2 {
  padding-bottom: 10px; }

.pl-2 {
  padding-left: 10px; }

.px-2 {
  padding-left: 10px;
  padding-right: 10px; }

.py-2 {
  padding-top: 10px;
  padding-bottom: 10px; }

.m-3 {
  margin: 15px; }

.mt-3 {
  margin-top: 15px; }

.mr-3 {
  margin-right: 15px; }

.mb-3 {
  margin-bottom: 15px; }

.ml-3 {
  margin-left: 15px; }

.mx-3 {
  margin-left: 15px;
  margin-right: 15px; }

.my-3 {
  margin-top: 15px;
  margin-bottom: 15px; }

.p-3 {
  padding: 15px; }

.pt-3 {
  padding-top: 15px; }

.pr-3 {
  padding-right: 15px; }

.pb-3 {
  padding-bottom: 15px; }

.pl-3 {
  padding-left: 15px; }

.px-3 {
  padding-left: 15px;
  padding-right: 15px; }

.py-3 {
  padding-top: 15px;
  padding-bottom: 15px; }

.m-4 {
  margin: 20px; }

.mt-4 {
  margin-top: 20px; }

.mr-4 {
  margin-right: 20px; }

.mb-4 {
  margin-bottom: 20px; }

.ml-4 {
  margin-left: 20px; }

.mx-4 {
  margin-left: 20px;
  margin-right: 20px; }

.my-4 {
  margin-top: 20px;
  margin-bottom: 20px; }

.p-4 {
  padding: 20px; }

.pt-4 {
  padding-top: 20px; }

.pr-4 {
  padding-right: 20px; }

.pb-4 {
  padding-bottom: 20px; }

.pl-4 {
  padding-left: 20px; }

.px-4 {
  padding-left: 20px;
  padding-right: 20px; }

.py-4 {
  padding-top: 20px;
  padding-bottom: 20px; }

.m-5 {
  margin: 25px; }

.mt-5 {
  margin-top: 25px; }

.mr-5 {
  margin-right: 25px; }

.mb-5 {
  margin-bottom: 25px; }

.ml-5 {
  margin-left: 25px; }

.mx-5 {
  margin-left: 25px;
  margin-right: 25px; }

.my-5 {
  margin-top: 25px;
  margin-bottom: 25px; }

.p-5 {
  padding: 25px; }

.pt-5 {
  padding-top: 25px; }

.pr-5 {
  padding-right: 25px; }

.pb-5 {
  padding-bottom: 25px; }

.pl-5 {
  padding-left: 25px; }

.px-5 {
  padding-left: 25px;
  padding-right: 25px; }

.py-5 {
  padding-top: 25px;
  padding-bottom: 25px; }

.m-6 {
  margin: 30px; }

.mt-6 {
  margin-top: 30px; }

.mr-6 {
  margin-right: 30px; }

.mb-6 {
  margin-bottom: 30px; }

.ml-6 {
  margin-left: 30px; }

.mx-6 {
  margin-left: 30px;
  margin-right: 30px; }

.my-6 {
  margin-top: 30px;
  margin-bottom: 30px; }

.p-6 {
  padding: 30px; }

.pt-6 {
  padding-top: 30px; }

.pr-6 {
  padding-right: 30px; }

.pb-6 {
  padding-bottom: 30px; }

.pl-6 {
  padding-left: 30px; }

.px-6 {
  padding-left: 30px;
  padding-right: 30px; }

.py-6 {
  padding-top: 30px;
  padding-bottom: 30px; }

.m-7 {
  margin: 35px; }

.mt-7 {
  margin-top: 35px; }

.mr-7 {
  margin-right: 35px; }

.mb-7 {
  margin-bottom: 35px; }

.ml-7 {
  margin-left: 35px; }

.mx-7 {
  margin-left: 35px;
  margin-right: 35px; }

.my-7 {
  margin-top: 35px;
  margin-bottom: 35px; }

.p-7 {
  padding: 35px; }

.pt-7 {
  padding-top: 35px; }

.pr-7 {
  padding-right: 35px; }

.pb-7 {
  padding-bottom: 35px; }

.pl-7 {
  padding-left: 35px; }

.px-7 {
  padding-left: 35px;
  padding-right: 35px; }

.py-7 {
  padding-top: 35px;
  padding-bottom: 35px; }

.m-8 {
  margin: 40px; }

.mt-8 {
  margin-top: 40px; }

.mr-8 {
  margin-right: 40px; }

.mb-8 {
  margin-bottom: 40px; }

.ml-8 {
  margin-left: 40px; }

.mx-8 {
  margin-left: 40px;
  margin-right: 40px; }

.my-8 {
  margin-top: 40px;
  margin-bottom: 40px; }

.p-8 {
  padding: 40px; }

.pt-8 {
  padding-top: 40px; }

.pr-8 {
  padding-right: 40px; }

.pb-8 {
  padding-bottom: 40px; }

.pl-8 {
  padding-left: 40px; }

.px-8 {
  padding-left: 40px;
  padding-right: 40px; }

.py-8 {
  padding-top: 40px;
  padding-bottom: 40px; }

.m-9 {
  margin: 45px; }

.mt-9 {
  margin-top: 45px; }

.mr-9 {
  margin-right: 45px; }

.mb-9 {
  margin-bottom: 45px; }

.ml-9 {
  margin-left: 45px; }

.mx-9 {
  margin-left: 45px;
  margin-right: 45px; }

.my-9 {
  margin-top: 45px;
  margin-bottom: 45px; }

.p-9 {
  padding: 45px; }

.pt-9 {
  padding-top: 45px; }

.pr-9 {
  padding-right: 45px; }

.pb-9 {
  padding-bottom: 45px; }

.pl-9 {
  padding-left: 45px; }

.px-9 {
  padding-left: 45px;
  padding-right: 45px; }

.py-9 {
  padding-top: 45px;
  padding-bottom: 45px; }

.m-10 {
  margin: 50px; }

.mt-10 {
  margin-top: 50px; }

.mr-10 {
  margin-right: 50px; }

.mb-10 {
  margin-bottom: 50px; }

.ml-10 {
  margin-left: 50px; }

.mx-10 {
  margin-left: 50px;
  margin-right: 50px; }

.my-10 {
  margin-top: 50px;
  margin-bottom: 50px; }

.p-10 {
  padding: 50px; }

.pt-10 {
  padding-top: 50px; }

.pr-10 {
  padding-right: 50px; }

.pb-10 {
  padding-bottom: 50px; }

.pl-10 {
  padding-left: 50px; }

.px-10 {
  padding-left: 50px;
  padding-right: 50px; }

.py-10 {
  padding-top: 50px;
  padding-bottom: 50px; }

.ml-auto {
  margin-left: auto; }

@media (min-width: 360px) {
  .text-xxs-left {
    text-align: left; }
  .text-xxs-right {
    text-align: right; }
  .text-xxs-center {
    text-align: center; }
  .text-xxs-justify {
    text-align: justify; } }

@media (min-width: 480px) {
  .text-xs-left {
    text-align: left; }
  .text-xs-right {
    text-align: right; }
  .text-xs-center {
    text-align: center; }
  .text-xs-justify {
    text-align: justify; } }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left; }
  .text-sm-right {
    text-align: right; }
  .text-sm-center {
    text-align: center; }
  .text-sm-justify {
    text-align: justify; } }

@media (min-width: 1024px) {
  .text-md-left {
    text-align: left; }
  .text-md-right {
    text-align: right; }
  .text-md-center {
    text-align: center; }
  .text-md-justify {
    text-align: justify; } }

@media (min-width: 1280px) {
  .text-lg-left {
    text-align: left; }
  .text-lg-right {
    text-align: right; }
  .text-lg-center {
    text-align: center; }
  .text-lg-justify {
    text-align: justify; } }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.word-break-all {
  word-break: break-all; }

.info-text {
  color: rgba(0, 0, 0, 0.7);
  font-style: italic;
  font-size: 13px;
  line-height: 17px; }

@media (min-width: 360px) {
  .float-xxs-left {
    float: left; }
  .floatxxs-right {
    float: right; } }

@media (min-width: 480px) {
  .float-xs-left {
    float: left; }
  .floatxs-right {
    float: right; } }

@media (min-width: 768px) {
  .float-sm-left {
    float: left; }
  .floatsm-right {
    float: right; } }

@media (min-width: 1024px) {
  .float-md-left {
    float: left; }
  .floatmd-right {
    float: right; } }

@media (min-width: 1280px) {
  .float-lg-left {
    float: left; }
  .floatlg-right {
    float: right; } }

.clear-after::after {
  content: " ";
  clear: both;
  display: block; }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

@media (min-width: 360px) {
  .dir-xxs-row {
    flex-direction: row; }
  .dir-xxs-column {
    flex-direction: column; }
  .justify-xxs-center {
    justify-content: center; }
  .justify-xxs-start {
    justify-content: flex-start; }
  .justify-xxs-end {
    justify-content: flex-end; }
  .justify-xxs-between {
    justify-content: space-between; }
  .justify-xxs-around {
    justify-content: space-around; }
  .align-xxs-stretch {
    align-items: stretch; }
  .align-xxs-center {
    align-items: center; }
  .align-xxs-start {
    align-items: flex-start; }
  .align-xxs-end {
    align-items: flex-end; }
  .content-xxs-center {
    align-content: center; }
  .content-xxs-start {
    align-content: flex-start; }
  .content-xxs-end {
    align-content: flex-end; }
  .content-xxs-between {
    align-content: space-between; } }

@media (min-width: 480px) {
  .dir-xs-row {
    flex-direction: row; }
  .dir-xs-column {
    flex-direction: column; }
  .justify-xs-center {
    justify-content: center; }
  .justify-xs-start {
    justify-content: flex-start; }
  .justify-xs-end {
    justify-content: flex-end; }
  .justify-xs-between {
    justify-content: space-between; }
  .justify-xs-around {
    justify-content: space-around; }
  .align-xs-stretch {
    align-items: stretch; }
  .align-xs-center {
    align-items: center; }
  .align-xs-start {
    align-items: flex-start; }
  .align-xs-end {
    align-items: flex-end; }
  .content-xs-center {
    align-content: center; }
  .content-xs-start {
    align-content: flex-start; }
  .content-xs-end {
    align-content: flex-end; }
  .content-xs-between {
    align-content: space-between; } }

@media (min-width: 768px) {
  .dir-sm-row {
    flex-direction: row; }
  .dir-sm-column {
    flex-direction: column; }
  .justify-sm-center {
    justify-content: center; }
  .justify-sm-start {
    justify-content: flex-start; }
  .justify-sm-end {
    justify-content: flex-end; }
  .justify-sm-between {
    justify-content: space-between; }
  .justify-sm-around {
    justify-content: space-around; }
  .align-sm-stretch {
    align-items: stretch; }
  .align-sm-center {
    align-items: center; }
  .align-sm-start {
    align-items: flex-start; }
  .align-sm-end {
    align-items: flex-end; }
  .content-sm-center {
    align-content: center; }
  .content-sm-start {
    align-content: flex-start; }
  .content-sm-end {
    align-content: flex-end; }
  .content-sm-between {
    align-content: space-between; } }

@media (min-width: 1024px) {
  .dir-md-row {
    flex-direction: row; }
  .dir-md-column {
    flex-direction: column; }
  .justify-md-center {
    justify-content: center; }
  .justify-md-start {
    justify-content: flex-start; }
  .justify-md-end {
    justify-content: flex-end; }
  .justify-md-between {
    justify-content: space-between; }
  .justify-md-around {
    justify-content: space-around; }
  .align-md-stretch {
    align-items: stretch; }
  .align-md-center {
    align-items: center; }
  .align-md-start {
    align-items: flex-start; }
  .align-md-end {
    align-items: flex-end; }
  .content-md-center {
    align-content: center; }
  .content-md-start {
    align-content: flex-start; }
  .content-md-end {
    align-content: flex-end; }
  .content-md-between {
    align-content: space-between; } }

@media (min-width: 1280px) {
  .dir-lg-row {
    flex-direction: row; }
  .dir-lg-column {
    flex-direction: column; }
  .justify-lg-center {
    justify-content: center; }
  .justify-lg-start {
    justify-content: flex-start; }
  .justify-lg-end {
    justify-content: flex-end; }
  .justify-lg-between {
    justify-content: space-between; }
  .justify-lg-around {
    justify-content: space-around; }
  .align-lg-stretch {
    align-items: stretch; }
  .align-lg-center {
    align-items: center; }
  .align-lg-start {
    align-items: flex-start; }
  .align-lg-end {
    align-items: flex-end; }
  .content-lg-center {
    align-content: center; }
  .content-lg-start {
    align-content: flex-start; }
  .content-lg-end {
    align-content: flex-end; }
  .content-lg-between {
    align-content: space-between; } }

.column {
  flex-direction: column; }

.justify-center {
  justify-content: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-between {
  justify-content: space-between; }

.justify-around {
  justify-content: space-around; }

.align-stretch {
  align-items: stretch; }

.align-center {
  align-items: center; }

.align-start {
  align-items: flex-start; }

.align-end {
  align-items: flex-end; }

.content-center {
  align-content: center; }

.content-start {
  align-content: flex-start; }

.content-end {
  align-content: flex-end; }

.content-between {
  align-content: space-between; }

.flex-wrap {
  flex-wrap: wrap; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.pointer {
  cursor: pointer; }

.w-25 {
  width: 25%; }

.w-50 {
  width: 50%; }

.w-70 {
  width: 70%; }

.w-75 {
  width: 75%; }

.w-100 {
  width: 100%; }

.mw-25 {
  max-width: 25%; }

.mw-50 {
  max-width: 50%; }

.mw-75 {
  max-width: 75%; }

.mw-100 {
  max-width: 100%; }

.border-bottom {
  border-bottom: 1px solid; }
  .border-bottom.black-10 {
    border-color: rgba(0, 0, 0, 0.1); }
  .border-bottom.primary-10 {
    border-color: rgba(83, 134, 212, 0.1); }

.bg-primary {
  background-color: #5386D4; }

.bg-primary-70 {
  background-color: rgba(83, 134, 212, 0.7); }

.bg-secondary {
  background-color: #000000; }

.bg-tertiary {
  background-color: #818ea7; }

.bg-highlight {
  background-color: #39a4cd; }

.bg-warning {
  background-color: #E43535; }

.bg-white {
  background-color: #FFFFFF; }

.bg-white-30 {
  background-color: rgba(255, 255, 255, 0.3); }

.bg-grey {
  background-color: #707070; }

.bg-black-20 {
  background-color: rgba(0, 0, 0, 0.2); }

.bg-black-30 {
  background-color: rgba(0, 0, 0, 0.3); }

.bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5); }

.bg-black-60 {
  background-color: rgba(0, 0, 0, 0.6); }

.bg-black-70 {
  background-color: rgba(0, 0, 0, 0.7); }

.bg-blue-light {
  background-color: #D4E1F4; }

.bg-blue-light-60 {
  background-color: rgba(212, 225, 244, 0.6); }

.hover-effect {
  transition: all 0.1s; }
  .hover-effect:hover {
    opacity: 0.7; }

.br-corner-xs {
  border-radius: 16px; }

.placeholder-text {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
  opacity: 0.4; }

/* Card styles */
.card {
  background-color: #ffffff;
  border: 1px solid rgba(67, 107, 170, 0.2);
  box-shadow: 1px 1px 3px rgba(65, 103, 161, 0.2); }
  .card.br-round {
    border-radius: 6px; }
  .card.br-corner {
    border-radius: 16px; }
  .card.highlight {
    box-shadow: 5px 5px 13px rgba(83, 134, 212, 0.16); }
  .card .card-content.hide {
    display: none; }
  .card.w-md {
    width: 425px; }

/* Check elements */
.checkbox {
  cursor: pointer;
  display: inline-flex;
  position: relative; }
  .checkbox .checkbox-label {
    transition: 0.1s;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px; }
  .checkbox.disabled {
    pointer-events: none; }
  .checkbox:hover {
    opacity: 0.7; }
  .checkbox.checkbox-no-hover:hover {
    opacity: 1; }
  .checkbox.br-corner {
    border-radius: 18px;
    padding: 5px 25px;
    border: 1px solid rgba(83, 134, 212, 0.2);
    width: 100%; }
    .checkbox.br-corner .check-icon {
      position: absolute;
      top: 0;
      right: -1px;
      bottom: 0;
      width: 29px;
      min-width: 29px;
      height: 29px;
      border-radius: 50%;
      background-color: #D4E1F4;
      border: 1px solid rgba(83, 134, 212, 0.2);
      display: inline-block; }
      .checkbox.br-corner .check-icon::before {
        content: "";
        font-family: "Icomoon Lexy";
        opacity: 0;
        transition: 0.3s;
        color: #ffffff;
        position: absolute;
        font-size: 14px;
        display: block;
        top: 7px;
        left: 7px; }
    .checkbox.br-corner .checkbox-label {
      padding-left: 10px; }
    .checkbox.br-corner input {
      display: none; }
      .checkbox.br-corner input:checked ~ .check-icon {
        opacity: 1;
        background-color: #5386D4; }
        .checkbox.br-corner input:checked ~ .check-icon::before {
          opacity: 1; }
  .checkbox.round .check-icon {
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 2px solid #5386D4;
    margin-right: 11px;
    transition: 0.3s;
    display: inline-block; }
    .checkbox.round .check-icon::before {
      content: "";
      font-family: "Icomoon Lexy";
      opacity: 0;
      transition: 0.3s;
      color: #ffffff;
      position: absolute;
      font-size: 15px;
      display: block;
      top: 1px;
      left: 2px;
      font-weight: 600; }
  .checkbox.round input {
    display: none; }
    .checkbox.round input:checked ~ .check-icon {
      opacity: 1;
      background-color: #5386D4; }
      .checkbox.round input:checked ~ .check-icon::before {
        opacity: 1; }
  .checkbox.disabled {
    opacity: 0.5;
    pointer-events: none; }

.react-select__option {
  cursor: pointer;
  display: inline-flex !important; }
  .react-select__option .check-icon {
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #5386D4;
    margin-right: 11px;
    transition: 0.3s;
    display: inline-block;
    position: relative; }
    .react-select__option .check-icon::before {
      content: "";
      font-family: "Icomoon Lexy";
      opacity: 0;
      transition: 0.3s;
      color: #ffffff;
      position: absolute;
      font-size: 15px;
      display: block;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 600; }
  .react-select__option input {
    display: none; }
    .react-select__option input:checked ~ .check-icon {
      opacity: 1;
      background-color: #5386D4; }
      .react-select__option input:checked ~ .check-icon::before {
        opacity: 1; }

/* Radio buttons */
.radio-button {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .radio-button.circle .radio-label {
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
    transition: 0.1s; }
    .radio-button.circle .radio-label:hover {
      opacity: 0.7; }
  .radio-button.circle .radio-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border: 2px solid rgba(83, 134, 212, 0.2);
    display: inline-block;
    border-radius: 50%;
    position: relative; }
    .radio-button.circle .radio-icon::after {
      content: "";
      display: inline-block;
      width: 10px;
      min-width: 10px;
      height: 10px;
      background-color: #5386D4;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      right: 3px;
      opacity: 0;
      transition: 0.1s; }
  .radio-button.circle input {
    display: none; }
    .radio-button.circle input:checked ~ .radio-icon {
      display: block;
      border-color: #5386D4; }
      .radio-button.circle input:checked ~ .radio-icon:after {
        opacity: 1; }
  .radio-button.br-corner {
    cursor: pointer; }
    .radio-button.br-corner .radio-label {
      margin-left: 10px; }
    .radio-button.br-corner .radio-icon {
      width: 35px;
      height: 35px;
      min-width: 35px;
      border-radius: 18px 18px 18px 0;
      background-color: #D4E1F4;
      border: 1px solid rgba(83, 134, 212, 0.2);
      position: relative; }
      .radio-button.br-corner .radio-icon::after {
        content: "";
        width: 12px;
        min-width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #5386D4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        opacity: 0;
        transition: 0.1s; }
    .radio-button.br-corner input {
      display: none; }
      .radio-button.br-corner input:checked ~ .radio-icon {
        opacity: 1;
        background-color: #ffffff;
        border-width: 2px;
        border-color: #5386D4; }
        .radio-button.br-corner input:checked ~ .radio-icon::after {
          opacity: 1; }

/* Pop up styles */
html[style*="calc"] header.main {
  width: calc(100% - 17px); }

.styles_modal__gNwvD {
  background: #ffffff;
  width: 100%;
  max-width: 570px;
  padding: 0;
  border-radius: 18px; }

.styles_closeIcon__1QwbI {
  fill: #707070;
  transition: 0.1s;
  cursor: pointer; }
  .styles_closeIcon__1QwbI:hover {
    opacity: 0.7; }

.popup-content {
  width: 100%;
  padding: 45px 40px;
  text-align: center;
  /* Popup custom styles */ }
  .popup-content .popup-title {
    color: #5386D4;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: 700;
    margin-bottom: 35px;
    text-transform: uppercase; }
  .popup-content .popup-img {
    width: 100%;
    max-width: 136px;
    display: inline-block;
    text-align: center;
    padding-top: 17px; }
    .popup-content .popup-img img {
      max-width: 100%;
      height: auto; }
  .popup-content .message {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom: 25px;
    max-width: 400px;
    margin: 0 auto; }
  .popup-content .popup-error-msg {
    color: #E43535;
    margin-top: 33px;
    margin-bottom: 25px;
    text-align: center; }
  .popup-content .options {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  .popup-content .ql-container.ql-snow, .popup-content .ql-toolbar.ql-snow {
    border-color: rgba(0, 0, 0, 0.3); }
  .popup-content .ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px; }
  .popup-content .ql-container.ql-snow {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 24px; }
  .popup-content .ql-editor {
    height: 230px; }
  .popup-content .social-signup li {
    max-width: 55px;
    margin-right: 5px; }

/* File upload */
.file-upload {
  position: relative;
  color: #5386D4;
  text-align: center;
  transition: 0.1s; }
  .file-upload .file-upload-input {
    outline: none;
    display: none; }
  .file-upload .file-upload-label {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    outline: none;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #5386D4;
    background-color: rgba(212, 225, 244, 0.4);
    border-radius: 18px; }
  .file-upload:hover {
    opacity: 0.7; }
  .file-upload.h-md {
    height: 90px; }
  .file-upload.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .file-upload.invalid .file-upload-label {
    border-color: #E43535; }
  .file-upload.sm {
    height: 45px;
    padding: 0 20px;
    width: 220px; }
    .file-upload.sm .file-upload-label {
      flex-direction: row;
      border-radius: 5px; }
      .file-upload.sm .file-upload-label i {
        margin-right: 15px;
        font-size: 22px; }

.upload-process.sm .doc-image {
  width: 28px;
  min-width: 28px;
  margin-right: 15px; }
  .upload-process.sm .doc-image img {
    width: 100%;
    height: auto; }

.upload-process.sm .doc-info {
  width: 380px; }

/* Progress bar styles */
.progress-bar {
  width: 100%;
  height: 6px;
  background-color: #D4E1F4;
  border-radius: 3px;
  position: relative; }
  .progress-bar .filled {
    position: absolute;
    height: 100%;
    border-radius: 3px;
    left: 0;
    background-color: #5386D4; }

/* Pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px; }
  .pagination li a {
    display: inline-block;
    text-align: center;
    margin: 0 5px;
    font-size: 16px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%; }
    .pagination li a.active {
      background-color: #5386D4;
      color: #ffffff; }
    .pagination li a.disabled {
      opacity: 0.5;
      pointer-events: none; }

/* Breadcrumbs styles */
.breadcrumbs li {
  display: inline-block; }

.breadcrumbs.main {
  margin-top: 37px; }
  .breadcrumbs.main li a {
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.7); }
    .breadcrumbs.main li a.active {
      color: #ffffff;
      pointer-events: none; }
  .breadcrumbs.main li::after {
    content: "";
    font-family: "Icomoon Lexy";
    display: inline-block;
    font-size: 10px;
    margin-right: 10px; }
  .breadcrumbs.main li:last-child::after {
    display: none; }

.breadcrumbs.dashboard {
  margin-left: 320px;
  background-color: #ffffff;
  padding: 8px 40px;
  border-bottom: 1px solid rgba(83, 134, 212, 0.2); }
  .breadcrumbs.dashboard li a {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: #5386D4;
    font-weight: 600;
    padding-right: 15px; }
    .breadcrumbs.dashboard li a.active {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      pointer-events: none; }
  .breadcrumbs.dashboard li::after {
    content: "";
    font-family: "Icomoon Lexy";
    display: inline-block;
    font-size: 8px;
    color: rgba(0, 0, 0, 0.2);
    margin-right: 15px; }
  .breadcrumbs.dashboard li:last-child::after {
    display: none; }

/*  Custom scroll */
.rcs-custom-scroll .rcs-inner-handle {
  background-color: rgba(0, 0, 0, 0.3) !important; }

.scrollbar-container {
  padding-right: 15px;
  width: calc(100% + 15px); }

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  background-color: transparent !important; }

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 6px !important;
  cursor: pointer; }

/* Phone number validation plugin styles */
.react-phone-number-input .rrui__input .rrui__select__native {
  display: none; }

.react-phone-number-input .rrui__input .rrui__select__button {
  background: none;
  border: none;
  margin-right: 15px;
  cursor: pointer;
  transition: 0.1s; }
  .react-phone-number-input .rrui__input .rrui__select__button:hover {
    opacity: 0.7; }

.react-phone-number-input .rrui__input .react-phone-number-input__icon--international {
  line-height: 36px; }

.react-phone-number-input .rrui__input .rrui__options-list {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 2px solid #5386D4; }

.react-phone-number-input .rrui__input .rrui__list__item {
  background-color: transparent;
  border: none;
  padding: 10px 27px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

.react-phone-number-input .rrui__input .rrui__expandable__close {
  display: none; }

.editor .editor-toolbar {
  margin-bottom: 25px; }
  .editor .editor-toolbar .text-format {
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    padding: 6px 7px;
    border-radius: 4px; }
    .editor .editor-toolbar .text-format:hover {
      opacity: 0.7; }
    .editor .editor-toolbar .text-format i {
      font-size: 16px;
      vertical-align: middle; }
    .editor .editor-toolbar .text-format.active {
      background: #eaeaea; }

.editor .btn-add {
  background: #5386D4;
  border: none;
  color: #ffffff;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  display: none;
  position: absolute;
  cursor: pointer;
  text-align: center;
  z-index: 2;
  font-size: 16px; }

.editor p, .editor span {
  background: transparent !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: initial; }

#editor ol, .view-mode ol {
  padding-left: 40px;
  margin: 14px 0; }

#editor ul, .view-mode ul {
  padding-left: 40px;
  margin: 14px 0;
  list-style: initial; }

#editor li, .view-mode li {
  list-style: inherit; }

#editor table, .view-mode table {
  max-width: 100% !important;
  width: 100%; }

#editor a, .view-mode a {
  color: #5386D4;
  cursor: pointer; }

#editor [title="footer"] span, .view-mode [title="footer"] span {
  width: 100% !important;
  height: auto !important;
  padding: 10px 12px !important; }

.line-height-130 {
  line-height: 130% !important; }

/* Switch styles */
.switch-wrap {
  display: flex;
  align-items: center; }
  .switch-wrap .switch-text {
    margin-left: 12px;
    font-size: 16px; }

.switch {
  display: inline-block;
  width: 34px;
  height: 14px;
  background-color: rgba(34, 31, 31, 0.26);
  border-radius: 16px;
  position: relative;
  cursor: pointer; }
  .switch .toggle {
    width: 20px;
    height: 20px;
    background-color: #F1F1F1;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: -3px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s ease;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.24); }
  .switch input {
    display: none; }
    .switch input:checked ~ .toggle {
      left: 17px;
      background: #5386D4; }

/* admin import page */
.import .file-import {
  position: relative; }
  .import .file-import.uploaded {
    display: flex;
    align-items: center; }
    .import .file-import.uploaded .file-upload {
      pointer-events: none; }
    .import .file-import.uploaded .upload-section {
      width: 100%;
      max-width: 216px;
      opacity: 0.5; }
    .import .file-import.uploaded .upload-process {
      display: flex; }
  .import .file-import.with-error .upload-process {
    width: calc(100% - 347px); }
  .import .file-import.disabled {
    pointer-events: none;
    opacity: .5; }
  .import .file-import .upload-process {
    width: calc(100% - 216px);
    padding-left: 70px;
    display: none; }
    .import .file-import .upload-process .doc-image {
      max-width: 36px;
      margin-right: 25px; }
      .import .file-import .upload-process .doc-image img {
        max-width: 100%;
        height: auto; }
    .import .file-import .upload-process .doc-info {
      width: calc(100% - 130px); }
  .import .file-import .icon-file {
    font-size: 38px; }
  .import .file-import .error-msg {
    position: static;
    margin-top: 5px; }

.import .info-text {
  margin-top: 8px; }

.import .import-loader {
  width: 100px;
  margin: 0 auto; }

/* admin manage component styles styles */
.manage .manage-file {
  padding: 11px 25px 11px 35px;
  display: flex;
  align-items: center;
  margin-bottom: 7px; }
  .manage .manage-file .public-document {
    margin-left: auto;
    margin-right: 25px;
    margin-top: 1px; }
    .manage .manage-file .public-document i {
      color: #5386D4;
      display: block;
      font-size: 24px; }
  .manage .manage-file .icon-arrow-right {
    margin-left: auto; }
  .manage .manage-file .content {
    width: 100%;
    max-width: 660px;
    margin-right: 30px; }
  .manage .manage-file .title {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: 600;
    font-size: 16px; }
  .manage .manage-file .card-item-left {
    flex-grow: 1; }
  .manage .manage-file .card-item-right {
    width: 40%;
    padding-right: 15px; }
  .manage .manage-file .sub-content {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 5px; }
    .manage .manage-file .sub-content a {
      color: #5386D4; }
  .manage .manage-file .actions {
    display: flex; }
    .manage .manage-file .actions li .btn-icon {
      font-size: 24px; }
    .manage .manage-file .actions .delete {
      color: #E43535; }
    .manage .manage-file .actions .approve {
      color: #0A9D00; }
  .manage .manage-file.disabled {
    opacity: 0.5;
    pointer-events: none; }

.manage .user-image {
  max-width: 170px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  .manage .user-image .error-msg {
    font-size: 14px;
    font-style: normal;
    bottom: -50px;
    width: calc(100% + 40px);
    left: -20px; }
  .manage .user-image .image {
    width: 128px;
    min-width: 128px;
    height: 128px;
    border-radius: 50%;
    border: 1px dashed rgba(83, 134, 212, 0.6);
    background-color: rgba(212, 225, 244, 0.4);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden; }
    .manage .user-image .image .hover-content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(83, 134, 212, 0.85);
      color: #ffffff;
      padding: 10px 0;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0; }
      .manage .user-image .image .hover-content label {
        cursor: pointer; }
      .manage .user-image .image .hover-content input {
        display: none; }
  .manage .user-image.uploaded .image {
    cursor: pointer;
    border: none; }
    .manage .user-image.uploaded .image:hover .hover-content {
      visibility: visible;
      opacity: 1; }
  .manage .user-image .image-upload {
    cursor: pointer;
    transition: 0.1s;
    color: #5386D4;
    display: inline-block;
    margin-top: 18px; }
    .manage .user-image .image-upload input {
      display: none; }
    .manage .user-image .image-upload i {
      margin-right: 9px; }
    .manage .user-image .image-upload:hover {
      opacity: 0.7; }

/* List view */
.list-view li {
  margin-bottom: 38px; }
  .list-view li .label {
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
    display: inline-block; }
  .list-view li .value {
    font-size: 16px; }

/* Tabs */
.tabs .tab {
  background-color: #ffffff;
  border: 1px solid rgba(83, 134, 212, 0.2);
  padding: 12px 0;
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.1s; }
  .tabs .tab.left {
    border-radius: 25px 0 0 6px; }
  .tabs .tab.right {
    border-radius: 0 25px 6px 0; }
  .tabs .tab.active {
    background-color: rgba(212, 225, 244, 0.5);
    border-color: #5386D4; }
    .tabs .tab.active p, .tabs .tab.active i {
      color: #5386D4; }
  .tabs .tab:hover {
    opacity: 0.7; }

/* Edit doc page */
.edit-doc .edit-doc-info {
  font-size: 16px;
  margin-bottom: 25px; }

.edit-doc .sticky {
  position: fixed;
  top: 0;
  z-index: 3;
  border: 1px solid rgba(67, 107, 170, 0.2);
  border-top: 0;
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  max-width: 623px;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  transition: all 0.1s;
  box-shadow: 5px 5px 13px rgba(83, 134, 212, 0.16); }

.edit-doc .edit-doc-content {
  flex-grow: 1; }

.edit-doc .lawyer-details .lawyer-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 85px;
  height: 85px;
  min-width: 85px;
  background-color: #5386D4;
  border-radius: 50%;
  display: inline-block;
  line-height: 85px;
  text-align: center;
  color: #ffffff;
  font-size: 24px; }

.edit-doc .lawyer-details li {
  margin-bottom: 15px; }

.edit-doc .doc-content {
  overflow: hidden; }
  .edit-doc .doc-content .card-header {
    background-color: #F5F5F5;
    display: flex;
    justify-content: space-between; }
    .edit-doc .doc-content .card-header .loader {
      padding: 5px 0 0 20px; }
  .edit-doc .doc-content .card-body {
    border-top: 1px solid rgba(67, 107, 170, 0.2);
    padding: 25px 50px 50px 50px; }
    .edit-doc .doc-content .card-body .new-note h6 {
      background-color: #395b90; }
  .edit-doc .doc-content .ql-container.ql-snow, .edit-doc .doc-content .ql-toolbar.ql-snow {
    border: none; }
  .edit-doc .doc-content ul {
    padding-left: 0; }
    .edit-doc .doc-content ul li {
      padding-left: 0 !important; }
      .edit-doc .doc-content ul li:before {
        display: none; }
      .edit-doc .doc-content ul li br {
        display: none; }
  .edit-doc .doc-content .edit-doc-section {
    transition: 0.1s;
    border: 1px solid transparent;
    border-radius: 16px;
    padding: 10px 12px !important;
    position: relative;
    margin-bottom: 5px;
    min-height: 40px; }
    .edit-doc .doc-content .edit-doc-section:hover, .edit-doc .doc-content .edit-doc-section.hovered {
      background-color: rgba(55, 178, 129, 0.12) !important;
      border-color: #338F6A; }
    .edit-doc .doc-content .edit-doc-section.empty {
      padding: 0 12px !important;
      margin: 0 !important;
      min-height: 5px;
      border: 0 !important;
      background-color: white !important; }
    .edit-doc .doc-content .edit-doc-section .add-note-icon {
      position: absolute;
      right: -15px;
      z-index: 999;
      background-color: #37B281;
      color: #ffffff;
      border: 1px solid #338F6A;
      outline: 0;
      cursor: pointer;
      font-size: 13px;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      font-weight: 600;
      transition: all 0.2s;
      height: 36px;
      width: 36px;
      min-width: 36px;
      border-radius: 50%; }
      .edit-doc .doc-content .edit-doc-section .add-note-icon:after {
        content: "";
        font-family: "Icomoon Lexy";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        right: 2px; }
  .edit-doc .doc-content .draft-title {
    border: none; }
  .edit-doc .doc-content .draftingNote {
    border-radius: 0 0 0  16px;
    padding-top: 11px;
    padding-bottom: 11px;
    position: relative;
    margin: 22px 12px 15px 12px;
    transition: 0.1s;
    user-select: none;
    background-color: #F8F6EF; }
    .edit-doc .doc-content .draftingNote .note-title {
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      font-weight: 700;
      padding: 0 55px;
      font-size: 14px;
      user-select: none;
      border: none;
      background: transparent;
      width: 100%; }
    .edit-doc .doc-content .draftingNote.expanded .btn-expand:after {
      content: ""; }
    .edit-doc .doc-content .draftingNote.expanded::before {
      border-bottom-left-radius: 0; }
    .edit-doc .doc-content .draftingNote.expanded .note-desc {
      display: block; }
    .edit-doc .doc-content .draftingNote.expanded .btn-delete {
      display: inline-block; }
    .edit-doc .doc-content .draftingNote.expanded .note-title {
      margin-bottom: 8px; }
    .edit-doc .doc-content .draftingNote.expanded::after {
      opacity: 1; }
    .edit-doc .doc-content .draftingNote::before {
      content: "";
      font-family: "Icomoon Lexy";
      position: absolute;
      top: 12px;
      left: 10px;
      height: 18px;
      line-height: 1;
      color: #D9B444;
      padding: 0 10px;
      font-size: 16px;
      border-bottom-left-radius: 16px;
      transition: 0.1s; }
    .edit-doc .doc-content .draftingNote::after {
      content: "";
      position: absolute;
      top: -9px;
      left: 0px;
      width: 10px;
      height: 10px;
      background: url("../img/drafting-note-icon.svg") no-repeat center center/10px 10px;
      opacity: 0;
      transition: 0.1s; }
  .edit-doc .doc-content .note-desc {
    padding: 10px 20px 20px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    display: none;
    margin-bottom: 15px;
    cursor: default; }
    .edit-doc .doc-content .note-desc.show {
      display: block;
      user-select: none; }
  .edit-doc .doc-content .btn-manage {
    color: #37B281;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    background: transparent;
    border: none; }
    .edit-doc .doc-content .btn-manage.btn-expand {
      position: absolute;
      top: 12px;
      right: 20px;
      color: #D9B444;
      width: 20px;
      height: 18px; }
      .edit-doc .doc-content .btn-manage.btn-expand:after {
        content: "";
        font-family: "Icomoon Lexy";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.1s;
        font-size: 12px; }
    .edit-doc .doc-content .btn-manage.btn-delete {
      position: absolute;
      right: 14px;
      bottom: 10px;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      font-size: 14px;
      z-index: 1;
      color: #E43535;
      display: none; }
      .edit-doc .doc-content .btn-manage.btn-delete::before {
        font-family: "Icomoon Lexy";
        content: "";
        margin-right: 10px; }
    .edit-doc .doc-content .btn-manage:hover {
      opacity: 0.7; }
  .edit-doc .doc-content.view-mode .editor-toolbar {
    display: none; }
  .edit-doc .doc-content.view-mode .edit-doc-section:hover {
    background-color: initial !important;
    border-color: transparent !important; }
  .edit-doc .doc-content.view-mode .edit-doc-section .add-note-icon {
    display: none; }
  .edit-doc .doc-content.view-mode .btn-add {
    display: none !important; }

.edit-doc .list-edit .label {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 15px; }

.edit-doc .list-edit .value {
  font-size: 16px; }

.edit-doc .doc-form-content {
  flex-shrink: 0; }
  .edit-doc .doc-form-content .checkbox {
    max-width: 90%; }

.edit-doc .switch-section li {
  margin-right: 15px; }

.edit-doc .link-box {
  background-color: rgba(245, 245, 245, 0.9);
  position: absolute;
  padding: 15px;
  z-index: 1;
  width: 100%;
  max-width: 300px; }
  .edit-doc .link-box .text-field input {
    height: 30px;
    line-height: 30px;
    font-size: 12px; }

.admin-content .edit-doc .edit-doc-content {
  width: calc(100% - 300px); }

.admin-content .edit-doc .doc-form-content {
  width: 300px; }
  .admin-content .edit-doc .doc-form-content .checkbox .checkbox-label {
    white-space: normal; }

.doc-view-mode-header h2 {
  max-width: calc(100% - 450px); }

.doc-view-mode-header .card li {
  max-width: 300px;
  display: flex; }
  .doc-view-mode-header .card li .label {
    white-space: nowrap; }
  .doc-view-mode-header .card li .value {
    font-size: 14px; }
  .doc-view-mode-header .card li a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%; }

.error {
  font-size: 14px;
  color: #E43535;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0; }

/* Search results page */
.search-results .items .filter {
  width: 100%;
  max-width: 399px; }
  .search-results .items .filter .card {
    padding: 36px 9px 67px 9px;
    margin-right: 15px; }
    .search-results .items .filter .card .scrollbar-container {
      padding-right: 0;
      width: 100%; }
    .search-results .items .filter .card.search-view {
      padding: 50px 0; }
      .search-results .items .filter .card.search-view h5 {
        margin-left: 60px; }
      .search-results .items .filter .card.search-view .list {
        margin-bottom: 25px;
        padding: 10px 25px 10px;
        border-bottom: 1px solid rgba(83, 134, 212, 0.2); }
        .search-results .items .filter .card.search-view .list:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0; }
  .search-results .items .filter .filter-list {
    padding-left: 20px; }
    .search-results .items .filter .filter-list li {
      margin-bottom: 8px; }
      .search-results .items .filter .filter-list li .checkbox {
        max-width: 320px; }

.search-results .items .files {
  width: 100%; }
  .search-results .items .files .file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 24px 14px 25px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: 0.1s;
    position: relative; }
    .search-results .items .files .file .paid-document {
      position: relative;
      padding: 6px;
      width: 24px;
      height: 24px;
      margin-left: auto;
      margin-right: 20px; }
      .search-results .items .files .file .paid-document:hover .tooltip-text {
        opacity: 1;
        visibility: visible; }
      .search-results .items .files .file .paid-document .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #37B281;
        display: block; }
      .search-results .items .files .file .paid-document .tooltip-text {
        font-size: 12px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-100%, -50%);
        padding: 6px 8px 8px;
        background: rgba(0, 0, 0, 0.8);
        color: rgba(255, 255, 255, 0.8);
        min-width: 130px;
        border-radius: 4px;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s; }
        .search-results .items .files .file .paid-document .tooltip-text:after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -5px;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-left: 6px solid rgba(0, 0, 0, 0.8);
          border-bottom: 6px solid transparent; }
    .search-results .items .files .file .file-info {
      display: flex;
      align-items: center;
      margin-right: 30px; }
      .search-results .items .files .file .file-info .details {
        margin-right: 25px; }
      .search-results .items .files .file .file-info .icon-doc {
        font-size: 20px;
        margin-right: 25px; }
      .search-results .items .files .file .file-info .title {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-weight: 600; }
      .search-results .items .files .file .file-info .desc {
        color: rgba(0, 0, 0, 0.5); }
        .search-results .items .files .file .file-info .desc a {
          color: #5386D4; }
      .search-results .items .files .file .file-info .title, .search-results .items .files .file .file-info .desc {
        max-width: 560px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .search-results .items .files .file .file-info .title a, .search-results .items .files .file .file-info .desc a {
          color: #5386D4; }
    .search-results .items .files .file i {
      color: #5386D4; }
    .search-results .items .files .file .icon-arrow-right {
      font-size: 12px; }
    .search-results .items .files .file:hover .file-info,
    .search-results .items .files .file:hover .icon-arrow-right {
      opacity: 0.7; }

#not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  #not-found .not-found {
    padding: 30px;
    text-align: center; }
    #not-found .not-found h1 {
      font-size: 180px;
      line-height: 1;
      margin-bottom: 60px;
      font-weight: normal;
      color: #7EA4DF; }
    #not-found .not-found h4 {
      text-transform: uppercase;
      letter-spacing: 3px; }
    #not-found .not-found p {
      font-size: 16px; }
    #not-found .not-found .btn {
      margin-top: 23px; }

.pricing-header-wrap {
  margin-top: 108px;
  background-color: #002e59; }
  .pricing-header-wrap .pricing-header {
    max-width: 700px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 270px; }
    .pricing-header-wrap .pricing-header h2 {
      color: #fff;
      text-transform: uppercase; }
    .pricing-header-wrap .pricing-header p {
      color: rgba(255, 255, 255, 0.7);
      margin-top: 20px;
      font-size: 16px; }

.pricing-plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .pricing-plans > li {
    margin: 0 15px 30px 15px;
    border-radius: 48px;
    border: 2px solid rgba(83, 134, 212, 0.2);
    overflow: hidden; }
  .pricing-plans .plan-head {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid rgba(83, 134, 212, 0.2);
    background-color: #ECF1FA; }
  .pricing-plans .plan-title {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin: auto; }
  .pricing-plans .plan-price {
    margin-top: 8px;
    color: #395E97;
    font-weight: 800;
    font-size: 41px;
    line-height: 50px; }
  .pricing-plans .current-plan {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 25px; }
  .pricing-plans .plan-body {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .pricing-plans .plan-features {
    margin-bottom: 45px; }
    .pricing-plans .plan-features li {
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.7);
      padding-left: 40px;
      position: relative;
      margin-bottom: 19px; }
      .pricing-plans .plan-features li:before {
        content: "";
        font-family: "Icomoon Lexy";
        font-size: 24px;
        position: absolute;
        left: 0;
        top: 0;
        color: #37B281; }

.current-plan {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding: 6px 18px;
  color: #fff;
  background: #5386D4;
  border-radius: 8px;
  white-space: nowrap; }

.user-pricing {
  margin-top: -201px;
  padding-bottom: 80px; }
  .user-pricing .pricing-plans > li {
    width: calc((100% - 91px) / 3); }
  .user-pricing .pricing-plans .plan-head {
    min-height: 200px;
    padding: 60px 68px 25px; }
  .user-pricing .pricing-plans .plan-body {
    padding: 40px 35px 50px;
    height: calc(100% - 200px); }
    .user-pricing .pricing-plans .plan-body .btn {
      margin-top: auto;
      text-transform: uppercase; }

.admin-pricing .container {
  max-width: 770px; }

.admin-pricing .pricing-plans > li {
  width: calc((100% - 61px) / 2); }

.admin-pricing .pricing-plans .plan-head {
  min-height: 160px;
  padding: 30px 52px 20px; }

.admin-pricing .pricing-plans .plan-body {
  padding: 40px 25px 40px;
  height: calc(100% - 160px); }
  .admin-pricing .pricing-plans .plan-body .btn-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto; }
  .admin-pricing .pricing-plans .plan-body .btn {
    min-width: 140px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center; }

.order-details-top {
  margin-top: 108px;
  background-color: #002e59;
  padding-bottom: 175px; }

.order-details {
  margin-top: -165px;
  padding-bottom: 100px; }
  .order-details .container > .card {
    max-width: 515px;
    margin: 0 auto;
    padding: 35px 45px 40px; }
  .order-details .order-head {
    position: relative;
    text-align: center;
    padding: 0 35px;
    margin-bottom: 30px; }
    .order-details .order-head .btn-icon {
      position: absolute;
      left: 0;
      top: 0; }
  .order-details .order-body a {
    color: #5386D4 !important; }
  .order-details .order-body .add-credit-card-popup {
    padding: 0 0 30px; }
    .order-details .order-body .add-credit-card-popup .popup-title {
      margin-bottom: 20px;
      font-size: 16px; }
    .order-details .order-body .add-credit-card-popup .text-field,
    .order-details .order-body .add-credit-card-popup .credit-card-input {
      margin-bottom: 20px; }
    .order-details .order-body .add-credit-card-popup .btn {
      margin-top: 10px; }
  .order-details .info-list {
    margin-bottom: 30px; }
    .order-details .info-list li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      padding-top: 17px; }
    .order-details .info-list .total {
      padding-top: 9px;
      margin-top: 17px;
      border-top: 1px solid rgba(0, 0, 0, 0.2); }
      .order-details .info-list .total p {
        color: #5386D4;
        font-size: 24px;
        font-weight: 700;
        margin-left: 30px; }
    .order-details .info-list span {
      color: rgba(0, 0, 0, 0.5); }
    .order-details .info-list p {
      font-weight: 500;
      color: #000000; }

.add-credit-card-popup .credit-card-input {
  margin-bottom: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  padding: 13px 15px;
  position: relative; }
  .add-credit-card-popup .credit-card-input.invalid {
    border-width: 2px;
    border-color: #E43535; }
    .add-credit-card-popup .credit-card-input.invalid .error-msg {
      left: 0; }

@media (max-width: 768px) {
  body {
    font-size: 13px; } }

/* Edit doc resp styles */
@media (max-width: 1360px) {
  .edit-doc .edit-doc-items {
    flex-wrap: wrap; }
  .edit-doc .edit-doc-content, .edit-doc .doc-form-content {
    width: 100%; }
  .edit-doc .doc-form-content {
    padding-left: 0; }
  .admin-content .edit-doc .edit-doc-content {
    width: 100%; }
  .admin-content .edit-doc .doc-form-content {
    width: 100%;
    margin-top: 20px; } }

/* Pricing resp styles */
@media (max-width: 1200px) {
  .user-pricing {
    padding-bottom: 50px; }
    .user-pricing .plan-head {
      padding: 50px 25px 25px; }
    .user-pricing .plan-body {
      padding: 30px 20px 40px; }
    .user-pricing .plan-features {
      margin-bottom: 30px; }
      .user-pricing .plan-features li {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 12px;
        padding-left: 35px; }
        .user-pricing .plan-features li:before {
          font-size: 22px; } }
