/* Text field styles */

.text-field {
	position: relative;
	margin-bottom: 35px;

	input, textarea, .ql-editor {
		width: 100%;
		padding: 0 28px;
		color: $color-secondary;
		font-size: $fs-md;
		font-family: $font-primary;
		transition: $trsn-dur;
		border: 1px solid transparent;

		&::placeholder {
			color: rgba($color-secondary, 0.5);
			opacity: 1;
		}
	}

	input {
		height: 47px;
	}

	textarea {
		resize: vertical;
		min-height: 50px;
		padding: 15px 28px;
	}

	&.br-corner {

		input, textarea {
			border-radius: $br-corner-lg;
			border: 1px solid rgba($color-secondary, 0.3);

			&:focus {
				padding-left: 28px;
				border-width: 2px;
				border-color: $color-primary;
				box-shadow: 0 2px 4px rgba($color-secondary, 0.16);
			}
		}

		label {
			font-size: 13px;
			color: rgba(#000000, 0.5);
			display: inline-block;
			margin-bottom: 4px;
			margin-left: 28px;
		}
	}

	&.icon {

		&.right-bg {

			input {
				padding-right: 55px;
			}

			.icon {
				position: absolute;
				right: 0;
				bottom: 0;
				top: 0;
				background-color: $color-primary;
				width: 48px;
				height: 47px;
				border-radius: $br-corner-lg;
				text-align: center;
				color: #ffffff;
				transition: $trsn-dur;

				i {
					line-height: 47px;
				}

				&:hover {
					opacity: 0.7;
				}
			}
		}

		&.right {

			input {
				padding-right: 50px;
			}

			i {
				position: absolute;
				right: 25px;
				font-size: 20px;
				line-height: 47px;
			}
		}
	}

	/* Invalid state */

	&.invalid {

		input, textarea {
			border-width: 2px;
			border-color: $color-warning;
		}
	}

	&.mb-0 {
		margin-bottom: 0;
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;

		input {
			background-color: rgba($color-blue-light, 0.4);
		}
	}

	&.sm {
		width: 155px;
	}
}

.error-msg {
	font-size: 11px;
	position: absolute;
	bottom: -20px;
	color: $color-warning;
	text-align: left;
	font-weight: 400;
	margin-bottom: 0;

	&.width-md {
		min-width: 130px;
	}
}
