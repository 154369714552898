.editor {

	// toolbar

	.editor-toolbar {
		margin-bottom: 25px;

		.text-format {
			background: none;
			border: none;
			cursor: pointer;
			transition: $trsn-dur-md;
			margin-right: 10px;
			width: 30px;
			height: 30px;
			padding: 6px 7px;
			border-radius: $br-default;

			&:hover {
				opacity: 0.7;
			}

			i {
				font-size: $fs-md;
				vertical-align: middle;
			}

			&.active {
				background: #eaeaea;
			}
		}
	}

	// add button

	.btn-add {
		background: $color-primary;
		border: none;
		color: #ffffff;
		width: 30px;
		min-width: 30px;
		height: 30px;
		border-radius: $br-circle;
		display: none;
		@include position();
		cursor: pointer;
		text-align: center;
		z-index: 2;
		font-size: $fs-md;
	}

	p, span {
		background: transparent!important;
		font-family: $font-primary;
		// font-size: $fs-sm;
		line-height: initial;
	}
}




#editor, .view-mode {
	ol {
		padding-left: 40px;
		margin: 14px 0;
	}

	ul {
		padding-left: 40px;
		margin: 14px 0;
		list-style: initial;
	}

	li {
		list-style: inherit;
	}

	table {
		max-width: 100% !important;
		width: 100%;
	}

	a {
		color: $color-primary;
		cursor: pointer;
	}

	[title="footer"] {

		span {
			width: 100% !important;
			height: auto !important;
			padding: 10px 12px !important;
		}
	}
}

.line-height-130 {
	line-height: 130% !important;
}