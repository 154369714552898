/* Footer styles */

footer {
	background-color: $color-blue-lighter;
	padding: 14px 0;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	&.dashboard {
		margin-left: 320px;;
	}
}
