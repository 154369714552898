.pricing-header-wrap {
	margin-top: 108px;
	background-color: $color-blue-dark;

	.pricing-header {
		max-width: 700px;
		text-align: center;
		margin: 0 auto;
		padding-bottom: 270px;

		h2 {
			color: #fff;
			text-transform: uppercase;
		}

		p {
			color: rgba(#fff, .7);
			margin-top: 20px;
			font-size: $fs-md;
		}
	}
}

.pricing-plans {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;

	> li {
		margin: 0 15px 30px 15px;
		border-radius: 48px;
		border: 2px solid $color-border;
		overflow: hidden;
	}

	.plan-head {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		border-bottom: 2px solid $color-border;
		background-color: #ECF1FA;
	}

	.plan-title {
		font-family: $font-secondary;
		font-size: 20px;
		line-height: 24px;
		font-weight: bold;
		text-transform: uppercase;
		margin: auto;
	}

	.plan-price {
		margin-top: 8px;
		color: $color-blue-middle;
		font-weight: 800;
		font-size: 41px;
		line-height: 50px;
	}

	.current-plan {
		position: absolute;
		@include XAlign;
		top: 25px;
	}

	.plan-body {
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.plan-features {
		margin-bottom: 45px;

		li {
			font-size: 16px;
			line-height: 19px;
			color: rgba($color-secondary, .7);
			padding-left: 40px;
			position: relative;
			margin-bottom: 19px;
			
			&:before {
				content: $icon-check;
				font-family: $font-icons;
				font-size: 24px;
				position: absolute;
				left: 0;
				top: 0;
				color: $color-green;
			}	
		}
	}
}

.current-plan {
	font-size: 14px;
	line-height: 17px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	padding: 6px 18px;
	color: #fff;
	background: $color-primary;
	border-radius: 8px;
	white-space: nowrap;
}

.user-pricing {
	margin-top: -201px;
	padding-bottom: 80px;

	.pricing-plans {

		> li {
			width: calc((100% - 91px) / 3);
		}

		.plan-head {
			min-height: 200px;
			padding: 60px 68px 25px;
		}

		.plan-body {
			padding: 40px 35px 50px;
			height: calc(100% - 200px);

			.btn {
				margin-top: auto;
				text-transform: uppercase;
			}
		}
	}
}

.admin-pricing {

	.container {
		max-width: 770px;
	}

	.pricing-plans {
		
		> li {
			width: calc((100% - 61px) / 2);
		}

		.plan-head {
			min-height: 160px;
			padding: 30px 52px 20px;
		}

		.plan-body {
			padding: 40px 25px 40px;
			height: calc(100% - 160px);

			.btn-group {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: auto;
			}

			.btn {
				min-width: 140px;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

	}
}

.order-details-top {
	margin-top: 108px;
	background-color: $color-blue-dark;
	padding-bottom: 175px;
}

.order-details {
	margin-top: -165px;
	padding-bottom: 100px;

	.container {

		> .card {
			max-width: 515px;
			margin: 0 auto;
			padding: 35px 45px 40px;
		}
	}

	.order-head {
		position: relative;
		text-align: center;
		padding: 0 35px;
		margin-bottom: 30px;
		
		.btn-icon {
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.order-body {
		a {
			color: $color-primary !important;
		}

		.add-credit-card-popup {
			padding: 0 0 30px;

			.popup-title {
				margin-bottom: 20px;
				font-size: 16px;
			}

			.text-field,
			.credit-card-input {
				margin-bottom: 20px;
			}

			.btn {
				margin-top: 10px;
			}
		}
	}
	
	.info-list {
		margin-bottom: 30px;

		li {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: $fs-md;
			padding-top: 17px;
		}

		.total {
			padding-top: 9px;
			margin-top: 17px;
			border-top: 1px solid rgba($color-secondary, .2);
			
			p {
				color: $color-primary;
				font-size: $fs-xl;
				font-weight: $fw-700;
				margin-left: 30px;
			}
		}

		span {
			color: rgba($color-secondary, .5);
		}

		p {
			font-weight: $fw-500;
			color: $color-secondary;
		}
	}
}

.add-credit-card-popup {

	.credit-card-input {
		margin-bottom: 40px;
		border: 1px solid rgba(0, 0, 0, 0.3);
		border-radius: 30px;
		padding: 13px 15px;
		position: relative;

		&.invalid {
			border-width: 2px;
			border-color: $color-warning;

			.error-msg {
				left: 0;
			}
		}
	}
}
