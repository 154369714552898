/* Header styles */

header {

	// Website header

	.brand-logo {
		width: 100%;
		max-width: 110px;
		margin-right: 22px;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.nav-items {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;
		border-bottom: 1px solid rgba(#FFFFFF, 0.5);
		font-family: $font-secondary;

		.logo {
			font-size: 25px;
			font-weight: $fw-600;
			margin-right: 100px;
		}

		.nav  {

			li {

				a {
					padding: 20px 29px;
				}
			}
		}

		.nav {
			display: flex;
			align-items: center;
			text-transform: uppercase;
			font-weight: $fw-700;

			li {

				.btn {
					min-width: 130px;
				}
			}
		}
	}

	&.main {
		background-color: $color-blue-dark;
		height: 100%;
		max-height: 176px;
		color: #FFFFFF;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 4;

		.search {
			padding-top: 35px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.text-field {
				width: 100%;
				max-width: 641px;

				input {
					box-shadow: 0 3px 6px rgba($color-secondary, 0.16);
				}
			}
		}
	}

	&.no-search {
		max-height: 110px;
		position: absolute;
	}

	// Dashboard header

	&.dashboard {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		background-color: #FFFFFF;
		border-bottom: 1px solid $color-border;
		height: 64px;
		margin-left: 320px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.text-field {
			transition: $trsn-dur-md;
			position: absolute;
			width: 100%;
			z-index: 1;
			align-items: center;
			display: none;

			input {
				height: 63px;
				line-height: 63px;
				box-shadow: 1px 1px 3px rgba(#4167A1, 0.16);
				display: none;
				padding-right: 125px;
			}

			.btn-icon {
				position: absolute;
				right: 45px;
				display: none;
			}

			&.show {
				display: flex;

				input {
					display: block;

					&:focus {
						box-shadow: 2px 2px 10px rgba(#4167A1, 0.3);
					}
				}

				.btn-icon {
					display: block;
				}
			}
		}

		.details {
			position: relative;

			.name-avatar {
				background-color: $color-primary;
				color: #ffffff;
				height: 40px;
				width: 40px;
				min-width: 40px;
				border-radius: $br-circle;
				text-align: center;
				line-height: 40px;
				text-transform: uppercase;
				display: inline-block;
				cursor: pointer;
				font-family: $font-secondary;
				font-size: 13px;
				font-weight: $fw-700;
				@include text-overflow();
			}

			#dropdown-label {
				line-height: 36px;
			}

			.details-dropdown {
				position: absolute;
				border-radius: 24px;
				background-color: #ffffff;
				box-shadow: 0 1px 6px rgba(#000000, 0.16);
				right: 0;
				top: 45px;
				padding: 26px 0 10px 0;
				display: none;
				z-index: 3;
				min-width: 300px;

				.dropdown-content {
					padding: 0 10px 17px 26px;
					border-bottom: 1px solid rgba($color-primary, 0.1);
					width: 300px;

					p, h3 {
						@include text-overflow();
						max-width: 200px;
					}
				}
			}
		}

		&.full {
			margin-left: 0;

			.container {
				justify-content: space-between;
			}
		}

		.profile-left {
			justify-content: flex-start;
			border-bottom: none;

			a {
				color: $color-primary;
			}
		}
	}
}
