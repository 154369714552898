// Paths

$fonts-folder: "../fonts";
$images-folder: '../img/';

// Fonts
$font-primary: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
$font-secondary: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
$font-icons: "Icomoon Lexy";

$font-family: (
		primary: $font-primary, secondary: $font-secondary );

// Color palette

$color-primary: #5386D4;
$color-primary-70: rgba($color-primary, 0.7);
$color-secondary: #000000;
$color-tertiary: #818ea7;
$color-highlight: #39a4cd;
$color-warning: #E43535;
$color-success: #0A9D00;
$color-grey: #707070;
$color-border: rgba($color-primary, 0.2);
$color-disable: rgba($color-primary, 0.3);

// Descriptive Base Colors

$color-text: $color-secondary;
$color-body: #F5F5F5;
$color-blue-light: #D4E1F4;
$color-blue-light-60: rgba($color-blue-light, 0.6);
$color-blue-middle: #395E97;
$color-green: #37B281;
$color-green-dark: #338F6A;
$color-blue-dark: #002e59;
$color-blue-lighter: #30699F;

$color-palette: (
		primary: $color-primary, primary-70: $color-primary-70, secondary: $color-secondary, tertiary: $color-tertiary, highlight: $color-highlight,
		warning: $color-warning, white: #FFFFFF, white-30: rgba(#FFFFFF, 0.3), grey: $color-grey,
		black-20: rgba(#000000, 0.2), black-30: rgba(#000000, 0.3), black-50: rgba(#000000, 0.5), black-60: rgba(#000000, 0.6), black-70: rgba(#000000, 0.7),
		blue-light: $color-blue-light, blue-light-60: $color-blue-light-60
)!default;

// Font-sizes

$fs-xs: 12px; $fs-sm: 14px; $fs-md: 16px; $fs-lg: 18px; $fs-xl: 24px; $fs-xxl: 30px;

// Base font size

$base-font-size: $fs-sm;

$text-font-sizes: (
		xs: $fs-xs, sm: $fs-sm, md: $fs-md, lg: $fs-lg, xl: $fs-xl, xxl: $fs-xxl
) !default;

/* Heading sizes are not final ones. Need to be specified with the design team. */

$heading-font-sizes: (
		h6: $fs-xs, h5: $fs-sm, h4: $fs-md, h3: $fs-lg, h2: $fs-xl, h1: $fs-xxl
) !default;

// Font weights

$fw-100: 100;
$fw-200: 200;
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;

$font-weights: (
		100: $fw-100, 200: $fw-200, 300: $fw-300, 400: $fw-400, 500: $fw-500, 600: $fw-600, 700: $fw-700, 800: $fw-800, 900: $fw-900
);

// Border radius

$br-sm: 2px;
$br-default: 4px;
$br-md: 5px;
$br-lg: 6px;
$br-xl: 18px;
$br-xxl: 29px;
$br-circle: 50%;
$br-none: 0;
$br-corner-xs: 16px;
$br-corner-sm: 19px;
$br-corner-lg: 30px;

// Transitions

$trsn-dur: .1s;
$trsn-dur-md: .2s;
$trsn-dur-lg: .3s;
$trsn-dur-xl: .5s;
$trsn-delay: 50ms;

// Container

$cont-width: 1170px;
$cont-sm-width: 830px;
$cont-padding: 30px;
$fluid-cont-padding: 60px;

// Resolutions

$screen-lg: 1280px;
$screen-md: 1024px;
$screen-sm: 768px;
$screen-xs: 480px;
$screen-xxs: 360px;
