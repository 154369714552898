// Floats

@each $key,$val in $resolutions {
  @media (min-width: $val) {
    // Alignment
    .float-#{$key}-left {
      float: left;
    }
    .float#{$key}-right {
      float: right;
    }
  }
}

// Fixes

.clear-after {
  @include clear-after;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

// Flexbox

@each $key,$val in $resolutions {
  @media (min-width: $val) {
    // Directions
    .dir-#{$key}-row {
      flex-direction: row;
    }

    .dir-#{$key}-column {
      flex-direction: column;
    }

    // Alignment
    .justify-#{$key}-center {
      justify-content: center;
    }

    .justify-#{$key}-start {
      justify-content: flex-start;
    }

    .justify-#{$key}-end {
      justify-content: flex-end;
    }

    .justify-#{$key}-between {
      justify-content: space-between;
    }

    .justify-#{$key}-around {
      justify-content: space-around;
    }

    .align-#{$key}-stretch {
      align-items: stretch;
    }

    .align-#{$key}-center {
      align-items: center;
    }

    .align-#{$key}-start {
      align-items: flex-start;
    }

    .align-#{$key}-end {
      align-items: flex-end;
    }

    .content-#{$key}-center {
      align-content: center;
    }

    .content-#{$key}-start {
      align-content: flex-start;
    }

    .content-#{$key}-end {
      align-content: flex-end;
    }

    .content-#{$key}-between {
      align-content: space-between;
    }
  }
}

.column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-stretch {
  align-items: stretch;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

// Cursor

.pointer {
  cursor: pointer;
}

// Width

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}
// Max width

.mw-25 {
  max-width: 25%;
}

.mw-50 {
  max-width: 50%;
}

.mw-75 {
  max-width: 75%;
}

.mw-100 {
  max-width: 100%;
}

// Borders

.border-bottom {
  border-bottom: 1px solid;

  &.black-10 {
    border-color: rgba(#000000, 0.1);
  }

  &.primary-10 {
    border-color: rgba($color-primary, 0.1);
  }
}

// Background colors

@each $key,$val in $color-palette {
  .bg-#{$key} {
    background-color: #{$val};
  }
}

.hover-effect {
  transition: all $trsn-dur;

  &:hover {
    opacity: 0.7;
  }
}


.br-corner-xs {
  border-radius: $br-corner-xs;
}

// Placeholders
.placeholder-text {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: $fw-600;
  margin: 20px 0;
  opacity: 0.4;
}
